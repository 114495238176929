@use "global" as *;
@use "sass:math";

.l-kinou-anchor {
    margin-top: myrem(81);

    @include mq("sm") {
        margin-top: myrem(60);
    }
}

.p-kinou-anchor {
}

.p-kinou-anchor__inner {
}

.l-inner {
}

.p-kinou-anchor__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: myrem(37.7);

    @include mq("sm") {
        grid-template-columns: 1fr 1fr;
        gap: myrem(20);
    }
}

.p-kinou-anchor__item {
    position: relative;
    text-align: center;
    color: $deepblue;
    font-weight: $bold;
    line-height: math.div(26, 18);
    font-size: myrem(18);
    padding: myrem(3) 0 myrem(10);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.3s;

    @include mq("sm") {
        font-size: myrem(22);
    }
}

.p-kinou-anchor__item:hover {
    opacity: 0.6;
}

.p-kinou-anchor__arrow {
    margin-top: myrem(3);
    aspect-ratio: 1/ 1;
    text-align: center;
    display: block;
    width: myrem(18);
    background-image: url("/img/2024/arrow-bottom.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @include mq("sm") {
        width: myrem(22);
    }
}
