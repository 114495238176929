@use "global" as *;
@use "sass:math";



.c-secttl__text{
    font-size: myrem(32);
    font-weight: $bold;
    letter-spacing: 0.1em;
    color: $textblue;
    text-align: center;
    line-height:  math.div(46, 32);
    

    @include mq("sm"){
        text-align: left;
    }
}

.c-secttl.c-secttl--large .c-secttl__text{
    font-size: myrem(40);
    letter-spacing: 0.05em;
    line-height:  math.div(58, 40);
    

}

.c-secttl.c-secttl--xlarge .c-secttl__text{
    font-size: myrem(48);
    letter-spacing: 0.05em;
    line-height:  math.div(80, 48);
}

.c-secttl.c-secttl--price .c-secttl__text{
    @include mq("sm"){
        text-align: center;
    }
}


.c-secttl__text .small{
    font-size: myrem(32);
    
}