@use "global" as *;
@use "sass:math";

.l-faq-main {
    margin-top: myrem(140);

    @include mq("sm") {
        margin-top: myrem(80);
    }
}
.p-faq-main__inner {
    padding-bottom: myrem(100);

    @include mq("sm") {
        padding-bottom: myrem(75);
    }
}
.p-faq-main__group {
    padding-top: myrem(30);

    @include mq("sm") {
        padding-top: myrem(20);
    }
}

.p-faq-main__group:not(:first-child) {
    margin-top: myrem(130);

    @include mq("sm") {
        margin-top: myrem(80);
    }
}

.p-faq-group__ttl {
    color: $deepblue;
    font-size: myrem(28);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height: math.div(40, 28);

    @include mq("sm") {
        font-size: myrem(24);
    }
}

.p-faq-group__items {
    margin-top: myrem(30);
}
