@use "global" as *;

.c-base-btn{
    display: inline-flex;
    border-radius: myrem(40);
    background-color: $deepblue;
    transition: all .2s;	
    width: myrem(298);

    @include mq("sm"){
        width: 80%;
    }
}


.c-base-btn a{
    position: relative;
    width: 100%;
    padding: myrem(16.11) myrem(8);
    font-size: myrem(16);
    text-align: center;
    color: $white;
    font-weight: $bold;

    @include mq("md"){
        font-size: myrem($sp-base-fs);
    }
}


.c-base-btn a span{
    position: absolute;
    width: myrem(24);
    height: myrem(24);
    background-color: $blue;
    top: 50%;
    right: myrem(18);
    transform: translateY(-50%);
    border-radius: 50%;
    transition: all .2s;
}

.c-base-btn a span::before,
.c-base-btn a span::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: myrem(8);
    height: myrem(8);
    background-color: $white;
    border-radius: 50%;
    transition: all .2s;
}

/* アニメーション */
.c-base-btn:hover{
    background-color: $blue;
}

.c-base-btn:hover a{
    // color: #003398;
}

.c-base-btn:hover a span{
    background-color: $white;
}

.c-base-btn:hover a span::before,
.c-base-btn:hover a span::after{
    border-radius: initial;
    width: myrem(7);
    height: myrem(1.5);
    background-color: $blue;

    @include mq("sm"){
        width: myrem(8);
        height: myrem(8);
        border-radius: 50%;
        
    }
}
.c-base-btn:hover a span::before{
    transform: translate(calc(-50% + myrem(1)),calc(-50% - myrem(2))) rotate(40deg);

    @include mq("sm"){
        transform: translate(-50%,-50%);
    }
}
.c-base-btn:hover a span::after{
    transform: translate(calc(-50% + myrem(1)),calc(-50% + myrem(2))) rotate(-40deg);

    @include mq("sm"){
        transform: translate(-50%,-50%);
    }
}


.c-base-btn.c-base-btn--green{
    background-color: #04986A;
}
.c-base-btn.c-base-btn--green a span{
    background-color: #59C100;
}

.c-base-btn.c-base-btn--green:hover{
    background-color: #59C100;
}

.c-base-btn.c-base-btn--green:hover a span{
    background-color: $white;
}

.c-base-btn.c-base-btn--green:hover a span::before,
.c-base-btn.c-base-btn--green:hover a span::after{
    background-color: #59C100;
}


.c-base-btn.c-base-btn--small{
    width: myrem(252);
    font-size: myrem(14);


    @include mq("md"){
        font-size: myrem($sp-base-fs);
        width: myrem(300);
      
    }

    @include mq("sm"){
        width: 80%;
    }
}


.c-base-btn.c-base-btn--small a{
    font-size: myrem(14);

    @include mq("md"){
        font-size: myrem($sp-base-fs);      
    }
}

.c-base-btn.c-base-btn--small{
    @include mq("sm"){
        // width: 85%;
    }
}

.c-base-btn.c-base-btn--small a{

    @include mq("sm"){
    font-size: 1rem;
    padding: .725rem 1.9rem .725rem myrem(20);
    letter-spacing: 0;
    }
}

.c-base-btn.c-base-btn--small:hover a{
    @include mq("sm"){
      color: $white;
    }
}

.c-base-btn.c-base-btn--small:hover a span{
    @include mq("sm"){
      background-color: $white;
    }
}
.c-base-btn.c-base-btn--small:hover a span::before,
.c-base-btn.c-base-btn--small:hover a span::after{
    @include mq("sm"){
        width: .3rem;
        height: .3rem;
        border-radius: 50%;
    }
}
.c-base-btn.c-base-btn--small:hover a span::before{

    @include mq("sm"){
      transform: initial;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

    }
}
.c-base-btn.c-base-btn--small:hover a span::after{

    @include mq("sm"){
        transform: initial;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }  
}


// large btn  top-mvで使用
.c-base-btn.c-base-btn--mv{
    width: myrem(346);

    @include mq("sm"){
      width: 85%;
    }
}

.c-base-btn.c-base-btn--mv a{
    padding: myrem(18.5) myrem(45);
    font-size: myrem(22);
    letter-spacing: 0.1em;    

    @include mq("sm"){
    font-size: 1rem;
    padding: .725rem 1.9rem .725rem myrem(20);
    letter-spacing: 0;
    }
}



.c-base-btn.c-base-btn--sp-white{
    @include mq("sm"){
        background-color: $white;
        border: 1px solid $textblue;
    }
}

.c-base-btn.c-base-btn--sp-white a{
    @include mq("sm"){
        color: $textblue;
    }
}

.c-base-btn.c-base-btn--sp-white a span{
    @include mq("sm"){
        background-color: $blue;
    }
}
.c-base-btn.c-base-btn--mv a span{
    width: myrem(40);
    height: myrem(40);
    right: myrem(18);

    @include mq("sm"){
      width: 1.1rem;
      height: 1.1rem;
    }
}

.c-base-btn.c-base-btn--mv a span::before,
.c-base-btn.c-base-btn--mv a span::after{
    left: calc(50% - .01rem);
    width: myrem(10);
    height: myrem(10);

    @include mq("sm"){
        width: .3rem;
        height: .3rem;
    }
}

.c-base-btn.c-base-btn--sp-white:hover{
    @include mq("sm"){
        background-color: $textblue;
    }
}
.c-base-btn.c-base-btn--mv:hover a{
    @include mq("sm"){
      color: $white;
    }
}
.c-base-btn.c-base-btn--mv:hover a span{
    @include mq("sm"){
      background-color: $white;
    }
}

.c-base-btn.c-base-btn--mv:hover a span::before,
.c-base-btn.c-base-btn--mv:hover a span::after{
    width: myrem(10);
    height: myrem(2);

    @include mq("sm"){
        width: .3rem;
        height: .3rem;
        border-radius: 50%;
    }
}
.c-base-btn.c-base-btn--mv:hover a span::before{
    transform: translate(calc(-50% + myrem(1)),calc(-50% - myrem(3))) rotate(40deg);

    @include mq("sm"){
      transform: initial;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

    }
}
.c-base-btn.c-base-btn--mv:hover a span::after{
    transform: translate(calc(-50% + myrem(1)),calc(-50% + myrem(3))) rotate(-40deg);

    @include mq("sm"){
        transform: initial;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }  
}