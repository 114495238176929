/* リキッドレイアウト対応 */
@use "global" as *;

// html {
//   //~375px
//   @media (max-width: 375px) {
//     font-size: vw(375, 16);
//   }

//   //375px~787px
//   font-size: 16px;

//   @include mq("md") {
//     font-size: vw(strip-unit($inner), 16);
//   }

//   //inner~max-screen
//   @media (min-width: $inner) {
//     font-size: 16px;
//   }
// }

// /* pcの電話番号発信対応 */
// a[href^="tel:"] {
//   @include mq("md") {
//     // pointer-events: none;
//   }
// }

// /* ホバー */
// a {
//   text-decoration: none;
//   color: inherit;
//   transition: all 0.3s;
//   @include mq("md") {
//     &:hover {
//       opacity: 0.8;
//       // background: gray;
//     }
//   }
// }

// body{
//   background: $white;
//   font-family: $base-font-family;
//   // position: relative;
// }

// ////////////////////
// // 非表示設定
// ////////////////////

//768px以上を表示
.u-desktop {
  display: none;

  @include mq("sm") {
    display: block;
  }
}

//モバイルのみ表示
.u-mobile {
  @include mq("sm") {
    display: none;
  }
}

// // body footer最下部表示
// body{
//   margin: 0;
//   padding: 0;
//   min-height: 100%;
//   display: flex;
//   flex-direction: column;

//   & .p-doby{
//     flex: 1;
//   }
// }