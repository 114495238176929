@use "global" as *;
@use "sass:math";

.p-bumon-anchor__inner.l-inner {
    max-width: myrem(986);

    @include mq("sm"){
        padding: 0 myrem(10);
    }
}

.p-bumon-anchor__items {
    display: flex;
    align-items: center;
    gap: myrem(198);

    @include mq("sm"){
        gap: myrem(10);
        justify-content: space-between;

    }
}

.p-bumon-anchor__item {
    flex: 1;
    transition: border-bottom-color .2s ,opacity .2s;
    border-bottom: 4px solid transparent;

}

.p-bumon-anchor__item:hover{
    opacity: .8;
    border-bottom-color:$textblue ;
}

.p-bumon-anchor__img {
    width: myrem(146);
    height: myrem(146);

    @include mq("sm"){
        width: myrem(120);
        height: myrem(120);
    }
}

.p-bumon-anchor__ttl {
    margin-top: myrem(8);
    color: $textblue;
    font-size: myrem(18);
    line-height:  math.div(26, 18);
    font-weight: $bold;
    text-align: center;
}

.p-bumon-anchor__arrow {
    transform: translateY(myrem(-4));
}

.c-circle-arrow{
    position: relative;
    display: inline-block;
    width: myrem(18);
    height: myrem(18);
    border-radius: 50%;
    background-color: $deepblue;
}

.c-circle-arrow::before,
.c-circle-arrow::after{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center bottom;
    width: 2px;
    height: myrem(7);
    border-radius: myrem(2);
    background-color: $white;
}

.c-circle-arrow::before{
    transform: translate(calc(-50% + myrem(0.5)),-50%) rotate(-45deg);

}

.c-circle-arrow::after{
    transform: translate(calc(-50% - myrem(0.5)),-50%) rotate(45deg);
}