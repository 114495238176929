@use "global" as *;
@use "sass:math";

.p-posts {
    padding-top: myrem(60);
    padding-bottom: myrem(50);
    background-color: $lightblue;
}

.p-posts__items {
    margin-top: myrem(19);

    @include mq("sm") {
        margin-top: myrem(30);
    }
}

.p-posts__btn {
    margin-top: myrem(13);
}
