@use "sass:math";

// ////////////////////
// // インナー幅設定
// ////////////////////
// // コンテンツのインナー幅（最も共通の幅が多い部分）
// $inner: 1100px;
// // ※キャンパス幅ではなくインナーの幅を指す

// // 余白
$padding-pc: 25;
$padding-sp: 25;
// // ※PC時：デザインカンプの余白の幅ではない。PC時は通常25pxで大丈夫！

// // header 高さ
// $header-height :80;

// ////////////////////
// // フォント設定
// ////////////////////

// $base-font-family: 'Noto Sans JP', sans-serif;;
$second-font-family: "Montserrat", sans-serif;
// $en-font-family: 'Oswald', sans-serif;

// // ※検証ツールでしっかり反映されているか確認する

// body {
//   font-family: $base-font-family;

// }

// フォントウェイト
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$fwblack: 900;

// ////////////////////
// // 色の指定
// ////////////////////

// //色指定（共通性のあるものは出来る限り指定すると良い）
// $black: #000000;
$white: #ffffff;
$gray: #a0a6aa;
$lightblue: #d7e4ec;
$blue: #0459e2;
$deepblue: #003398;
$textblue: #002366;
$brown: #cc9d4b; // Price Premium
// ////////////////////
// // メディアクエリとブレークポイントの設定
// ////////////////////

//ブレイクポイント
$breakpoints: (
    sm: 599,
    md: 782,
    lg: 1000,
    pc: 1441,
    xl: 1600,
);

//メディアクエリー
$mediaquerys: (
    // sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
    // md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
    // lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
    // xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
    // pc: "screen and (min-width: #{map-get($breakpoints,'pc')}px)",
    sm: "screen and (max-width: #{map-get($breakpoints,'sm')}px)",
    md: "screen and (max-width: #{map-get($breakpoints,'md')}px)",
    lg: "screen and (max-width: #{map-get($breakpoints,'lg')}px)",
    xl: "screen and (max-width: #{map-get($breakpoints,'xl')}px)",
    pc: "screen and (max-width: #{map-get($breakpoints,'pc')}px)"
);

// //z-index
// $header_z-index: 9990;
// $main-view__main_z-index: 2; // main-view swiperがz-index:1;なので + 1

// @include mq(){}で書くとブレークポイントが反映される（初期値はmd）
@mixin mq($mediaquery: md) {
    @media #{map-get($mediaquerys, $mediaquery)} {
        @content;
    }
}

// // remへの計算式（16pxを基準としている。10pxを基準とする場合は16を10に変更する）
// 特殊仕様　元のCSSに合わせるため
@function myrem($pixels) {
    @return math.div(math.div($pixels, 2), 10) * 1rem;
}

$sp-base-fs: 24;

$base-shadow: myrem(4) myrem(4) myrem(10) #d0dffc;
$base-anime-duration: 0.3s;
