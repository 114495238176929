@use "global" as *;
@use "sass:math";

.p-solution-move__inner.l-inner {
    max-width: myrem(1150);
}

.p-solution-move__items {
    display: flex;
    gap: myrem(4);
    justify-content: space-between;
    justify-content: center;

    @include mq("sm") {
        gap: myrem(18);
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }
}

.p-solution-move__link {
    position: relative;
    background-color: $white;
    width: 100%;
    font-size: myrem(18);
    font-weight: $bold;
    color: $textblue;
    border: 1px solid $textblue;
    padding: myrem(13.71);
    transition: opacity 0.3s;
    max-width: myrem(272);
    line-height: 1;

    @include mq("sm") {
        // max-width: 80%;
        max-width: 100%;
        line-height: 1.2;
        // width: calc(50% - myrem(3));
        width: 100%;
        font-size: myrem(28);
        text-align: left;
    }
}

.p-solution-move__link span {
    font-size: myrem(10);

    @include mq("sm") {
        // display: block;
        font-size: myrem(18);
    }
}

.p-solution-move__link::before {
    content: "";
    position: absolute;
    right: myrem(12);
    top: 50%;
    transform: translateY(-50%);
    width: myrem(20);
    height: myrem(20);
    background-image: url("/img/2024/solution-icon.svg");
    background-size: 100% 100%;
}

.p-solution-move__link:hover {
    opacity: 0.5;
}
