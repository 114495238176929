@use "global" as *;


.c-breadcrumb {
    font-size: myrem(9);

    @include mq("md") {
        font-size: myrem(15);
    }
    @include mq("sm") {
        font-size: myrem(16);
    }
}

.c-breadcrumbs__inner {
    padding: 0 myrem(22);
    max-width: myrem(1100);
    margin-left: auto;
    margin-right: auto;
    
    @include mq("sm"){
        padding: 0 myrem(10);
    }
}


.c-breadcrumbs__main span {
    // color: $black;

}

.c-breadcrumbs__main span{
    font-size: myrem(12);
    font-weight: $regular;
    letter-spacing: 0.05em;

    @include mq("md"){
        font-size: myrem(18);
    }

    @include mq("sm"){
        font-size: myrem(20);
    }
}

.c-breadcrumbs__main span[data-delimiter="delimiter"] {

    // display: inline-block;
    // width: myrem(20);
    // padding: 0.5px 0;
    // margin-left: myrem(10);
    // margin-right: myrem(10);

    // vertical-align: middle;
}
.c-breadcrumbs__main .home,
.c-breadcrumbs__main{
    color: $textblue;
}

.c-breadcrumbs__main > span{
    margin-left: myrem(10);
    margin-right: myrem(10);
}

.c-breadcrumbs__main span.current-item{
    text-decoration: underline;
    text-decoration-color: $textblue;
}