@use "global" as *;
@use "sass:math";

.c-items {
}
.c-items__top-ttl {
    font-size: myrem(22);
    line-height: math.div(36, 22);
    letter-spacing: 0.05em;
    font-weight: $bold;
    color: #002366;
    background-color: #eef1f7;
    padding-top: myrem(5);
    padding-bottom: 5px;
}
.c-items__main {
    margin-top: myrem(56);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: myrem(60);
    column-gap: myrem(62);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: $textblue;

    @include mq("sm") {
        margin-top: myrem(30);
        grid-template-columns: 1fr;
        max-width: 100%;
    }
}

.c-items__item {
    /*グリットアイテムを3グリット分の大きさにする*/
    // grid-row: span 3;
    /*サブグリットの指定*/
    // display: grid;
    // grid-template-rows: subgrid;
    // gap: 0;
    width: 100%;
    text-align: center;
    // justify-items: center;
}

.c-items__img {
    aspect-ratio: 344 / 220;
    width: 100%;
    height: auto;
    @include mq("sm") {
    }
}

.c-items__img {
    // width: 100%;
    // align-self: center;
}

.c-items__ttl {
    margin-top: myrem(8);
    font-size: myrem(18);
    letter-spacing: 0.05em;
    line-height: math.div(26, 18);
    font-weight: $bold;

    @include mq("sm") {
        margin-top: myrem(10);
        font-size: myrem(22);
    }
}

.c-items__ttl > span {
    font-size: myrem(16);
}

.c-items__text {
    text-align: left;
    font-size: myrem(16);
    font-weight: $regular;
    letter-spacing: 0.05em;
    line-height: math.div(28, 16);
    margin-top: myrem(8);

    @include mq("sm") {
        margin-top: myrem(10);
        font-size: myrem(18);
    }
}
