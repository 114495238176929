@use "global" as *;
@use "sass:math";


.c-product {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: myrem(60);
    column-gap: myrem(62);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: $textblue;
    // max-width: myrem(1036);

    @include mq("sm"){
        grid-template-columns: 1fr;
        // max-width: myrem(500);
        max-width: 100%;
    }
}

.c-product__item {
    /*グリットアイテムを3グリット分の大きさにする*/
    grid-row: span 3;
    /*サブグリットの指定*/
    display: grid;
    grid-template-rows: subgrid;
    gap: 0;
    width: 100%;
    text-align: center;
    justify-items: center;
}

.c-product__img {
    width: 76.31578947%;

    @include mq("sm"){
        width: 60%;
    }
}

.c-product__img.c-product__img--center{
    width: 100%;
    align-self: center;

}

.c-product__ttl {
    margin-top: myrem(15);
    font-size: myrem(22);
    letter-spacing: 0.05em;
    line-height:  1.5;
    font-weight: $bold;


    @include mq("sm"){
        margin-top: myrem(20);
        font-size: myrem(28);
    }
}

.c-product__ttl > span{
    font-size: myrem(16);
    
}

.c-product__text {
    text-align: left;
    font-size: myrem(16);
    font-weight: $regular;
    letter-spacing: 0.05em;
    line-height:  math.div(28, 16);
    margin-top: myrem(10);
    
    @include mq("sm"){
        margin-top: myrem(10);
        font-size: myrem(24);
    }
}
