@use "global" as *;
@use "sass:math";

.c-dx {
    color: $textblue;
}

.c-dx__top{
    display: flex;
    align-items: center;
    justify-content:space-between ;
    
    @include mq("sm"){
        flex-direction: column;
    }
}

.c-dx__left {
    text-align: left;

    @include mq("sm"){
        width: 100%;
    }
}

.c-dx__ttl {
    font-size: myrem(48);
    font-weight: $medium;
    margin-top: myrem(32);
    line-height: 1;

    @include mq("md"){
        color: $blue;
        margin-top: initial;
    }
}

.c-dx__ttl span{
    position: relative;
    top: myrem(4);
    margin-left: myrem(5);
    font-size: myrem(58);
    font-family: $second-font-family;
    
}

.c-dx__subttl {
    margin-top: myrem(40);
    font-size: myrem(28);
    letter-spacing: 0.05em;
    line-height:  math.div(40, 28);
    font-weight: $bold;

    @include mq("sm"){
        margin-top: myrem(20);
    }
}

.c-dx__text{
    margin-top: myrem(10);
    font-size: myrem(15);
    font-weight: $regular;

    @include mq("sm"){
        font-size: myrem(22);
      
    }
}

.c-dx__right {

}

.c-dx__img {
    width: myrem(520);

    @include mq("sm"){
        width: 100%;
        margin-top: myrem(20);
    }
}



.c-dx__items{
    margin-top: myrem(48);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: myrem(28);
    column-gap: myrem(98);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    @include mq("sm"){
        grid-template-columns: 1fr;
        max-width: myrem(800);
        row-gap: myrem(42);
    }
}

.c-dx__item{
    /*グリットアイテムを3グリット分の大きさにする*/
    grid-row: span 4;
    /*サブグリットの指定*/
    display: grid;
    grid-template-rows: subgrid;
    gap: 0;
    width: 100%;
    text-align: left;
    color: $textblue;
}

.c-dx__item-ttl {
    font-size: myrem(22);
    line-height:  math.div(32, 22);
    letter-spacing: 0.05em;
    font-weight: $bold;

    @include mq("sm"){
        font-size: myrem(26);
    }
}

.c-dx__item-img {
    margin-top: myrem(10);
    aspect-ratio: 280 / 112;
    width: 100%;
}

.c-dx__item-subttl {
    margin-top: myrem(13);
    font-size: myrem(16);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height:  math.div(25, 17);

    @include mq("sm"){
        font-size: myrem(20);
    }
}

.c-dx__item-text {
    margin-top: myrem(10);
    font-size: myrem(16);
    line-height:  math.div(28, 16);
    font-weight: $regular;

    @include mq("sm"){
        font-size: myrem(20);
    }
}

