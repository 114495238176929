@use "global" as *;
@use "sass:math";

.c-page-catch {
    color: $textblue;
}

.c-page-catch__inner {

}
.c-page-catch__main {
    font-size: myrem(28);
    line-height:  math.div(42, 28);
    font-weight: $bold;
    letter-spacing: 0.05em;

    @include mq("sm"){
        font-size: myrem(32);
        text-align: left;
    }
}

.c-page-catch__sub {
    margin-top: myrem(10);
    font-size: myrem(20);
    font-weight: $regular;
    line-height:  math.div(34, 20);

    @include mq("sm"){
        font-size: myrem($sp-base-fs);
        text-align: left;      
    }
}

.c-page-catch__img{
    margin-top: myrem(23);

    @include mq("sm"){
        width: 100%;
    }
}


.c-page-catch__img.c-page-catch__img--cx{
    width: myrem(800);
}

.c-page-catch__img.c-page-catch__img--ex{
    width: myrem(681)
}