@use "global" as *;
@use "sass:math";

/* =====================================================
pagination CSS ページナビ　WP pagenavi
===================================================== */
$color_main: #001e63;

.c-pagination {
    .wp-pagenavi {
        text-align: center;
        position: relative;

        .pages {
            display: none;
        }

        span,
        a {
            /* 数字部分の共通CSS　大きさなど */
            margin: 0 myrem(7);
            font-weight: $regular;
            line-height: 1;
            color: $gray;
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            aspect-ratio: 1 / 1;
            width: myrem(60);
            border-radius: 50%;
            font-size: myrem(18);
            border: 1px solid transparent;
            transition: border 0.1s;

            @include mq("sm") {
                width: 2.5rem;
            }
        }
        span.current {
            color: $white;
            background-color: $blue;
        }

        a:hover {
            /* 現在のページ */
            border: 1px solid $blue;
        }

        /* その他、必要があれば以下のクラスにcssを指定する */
        .previouspostslink {
            color: transparent;
            background: url(/img/2024/arrow_left.svg) no-repeat center center;
            background-size: 6px auto;
        }
        .nextpostslink {
            color: transparent;
            background: url(/img/2024/arrow_right.svg) no-repeat center center;
            background-size: 6px auto;
        }

        .first,
        .last {
            display: none;
        }

        .smaller {
        }

        .larger {
        }
        .extend {
            // display: none;
        }
        .extend:last-of-type {
            display: none;
        }
    }
}
