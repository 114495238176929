@use "global" as *;
@use "sass:math";

.p-mv-sub {
    background-color: $white;
}

.p-mv-sub__inner {
    display: flex;
    justify-content: space-between;
    max-width: myrem(1100);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;

    @include mq("sm") {
        flex-direction: column;
        padding: myrem(25) myrem(15) 0;
    }
}

.p-mv-sub__main {
    margin-top: myrem(30);
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    @include mq("sm") {
        margin-top: initial;
        flex-direction: column;
        text-align: center;
        width: 100%;
    }
}

.p-mv-sub__subttl {
    color: $deepblue;
    font-size: myrem(20);
    letter-spacing: 0.1em;
    line-height: math.div(29, 20);
    font-weight: $bold;

    @include mq("sm") {
        font-size: myrem(28);
        text-align: left;
    }
}

.p-mv-sub__ttl {
    display: block;
    color: $deepblue;
    width: 100%;
    font-size: myrem(42);
    line-height: math.div(66, 42);
    letter-spacing: 0.05em;
    font-weight: $fwblack;

    @include mq("sm") {
        margin-top: myrem(20);
        font-size: myrem(58);
        margin-left: initial;
        text-align: left;
        line-height: 1.2;
    }
}
.p-mv-sub__ttl > .blue {
    color: #0057ff;
}
.p-mv-sub__ttl > .non {
}

.p-mv-sub__lists {
    margin-left: myrem(14);
    margin-top: myrem(6);

    @include mq("sm") {
        margin-top: myrem(10);
    }
}

.p-mv-sub__list {
    display: flex;
    align-items: center;
    position: relative;
    font-size: myrem(18);
    letter-spacing: 0.1em;
    line-height: math.div(28, 18);
    font-weight: $bold;
    text-align: left;

    @include mq("md") {
        font-size: myrem($sp-base-fs);
    }
}

.p-mv-sub__list::before {
    content: ""; /* 空の要素作成 */
    position: absolute;
    width: myrem(15); /* 幅指定 */
    height: myrem(15); /* 高さ指定 */
    display: inline-block; /* インラインブロックにする */
    background-color: #0057ff; /* 背景色指定 */
    position: relative; /* 位置調整 */
    top: myrem(9); /* 位置調整 */
    transform: translateY(-50%);
    margin-right: 0.5rem; /* 余白指定 */
}

.p-mv-sub__btns {
    margin-top: myrem(11);
    display: flex;
    gap: myrem(11.7);

    @include mq("sm") {
        margin-top: 1.6rem;
        gap: 1rem;
        order: 4;
        align-items: center;
        padding: 0 0.5rem;
    }
}

.p-mv-sub__results {
    margin-top: myrem(16);
    margin-left: myrem(5);
    display: flex;
    align-items: center;
    gap: myrem(10);

    @include mq("sm") {
        margin-left: initial;
        justify-content: center;
    }
}

.p-mv-sub__result-img {
    width: myrem(271.9);
}

.p-mv-sub__note {
    margin-top: myrem(4);
    margin-left: myrem(12);
    font-size: myrem(14);
    font-weight: $regular;
    line-height: math.div(20, 14);

    @include mq("sm") {
    }
}

.p-mv-sub__img {
    aspect-ratio: 400 / 288;
    width: myrem(400);

    @include mq("sm") {
        margin-top: myrem(50);
        width: 100vw;
    }
}
.p-mv-sub__img img {
    height: 100%;
    object-fit: cover;

    @include mq("sm") {
        height: inherit;
        object-fit: initial;
    }
}

.p-mv-sub.p-mv-sub--faq .p-mv-sub__img {
    @include mq("sm") {
        aspect-ratio: initial;
    }
}

.p-mv-sub.p-mv-sub--news .p-mv-sub__main,
.p-mv-sub.p-mv-sub--faq .p-mv-sub__main {
    margin-top: initial;
}
