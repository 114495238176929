@use "global" as *;
@use "sass:math";
.l-consult {
}

.p-consult {
    
    @include mq("sm"){
        overflow: hidden;
    }
}

.p-consult__inner.l-inner {
    padding-top: myrem(50);
    padding-bottom: myrem(100);
    max-width: myrem(1100);

    @include mq("sm"){
        max-width: 100%;
        padding-bottom: myrem(15);
    }

}

.p-consult__ttl {
    color: $blue;
    font-size: myrem(42);
    font-weight: $bold;
    text-align: center;
    
}

.p-consult__text {
    
    margin-top: myrem(20);
    line-height: 1.5;
    font-size: myrem(16);
    text-align: center;

    @include mq("sm"){
        text-align: left;
        margin-top: myrem(30);
        font-size: myrem(24);
        line-height: 1.7;
    }
}

.p-consult__recommend{
    // max-width: myrem(350);
    margin-top: myrem(30);
    margin-left: auto;
    margin-right: auto;

    @include mq("sm"){
      margin-top: myrem(45);
    }
}

.p-consult__lists-ttl{
    color: $textblue;
    font-size: myrem(20);
    line-height: 1.5;
    font-weight: $bold;
    
    @include mq("sm"){
      font-size: myrem(26);
      text-align: left;
    }
}
.p-consult__lists-ttl::before,
.p-consult__lists-ttl::after{
    content: "～";
    font-size: inherit;
    
}


.p-consult__lists{
    text-align: left;
    margin-top: myrem(5);
    margin-left: auto;
    margin-right: auto;
    font-size: myrem(18);
    font-weight: $bold;
    line-height: 1.5;
    text-align: center;
    width: fit-content;

    @include mq("sm"){
        text-align: left;
        font-size: myrem(24);
        line-height: 1.7;
        margin-left: initial;
    }
}

.p-consult__lists li{
    text-align: left;
    width: fit-content;
}


.p-consult__lists li::before{
    content: "・";
    color: inherit;
}


.p-consult__form{
    margin-top: myrem(50);
}

.p-consult__form{
    width: 100%;

    @include mq("md"){
      height: initial;
    }
}

.p-consult__form iframe{
    width: 100%;
}