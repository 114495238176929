@use "global" as *;
@use "sass:math";

.p-solution__items{
    display: grid;
    margin-top: myrem(33);
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: myrem(32);
    column-gap: myrem(32);
    max-width: myrem(1102);
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include mq("sm"){
        grid-template-columns: 1fr;
        max-width: 100%;
        row-gap: myrem(50);
    }
}

.p-solution__item{
    /*グリットアイテムを3グリット分の大きさにする*/
    grid-row: span 6;
    /*サブグリットの指定*/
    display: grid;
    grid-template-rows: subgrid;
    gap: 0;
    width: 100%;
}

.p-solution-item{
    background-color: $white;
    padding: myrem(19) 0 myrem(38);
    background-color: $white;
    text-align: center;
    box-shadow: $base-shadow;
    color: $textblue;
}

.p-solution-item > *{
    padding-left: myrem(24);
    padding-right: myrem(24);
}

.p-solution-item__ttl {
    font-size: myrem(96);
    letter-spacing: 0.05em;
    color: #0057FF;
    text-align: left;
    line-height: 1;
    font-weight: $regular;
    padding-left: myrem(17);
    padding-right: myrem(17);
    font-family: $second-font-family;

    @include mq("sm"){
        font-size: myrem(90);
    }
}

.p-solution-item__subttl{
    text-align: left;
    font-size: myrem(16);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height:  math.div(20, 16);
    margin-top: myrem(9);

    @include mq("md"){
        font-size: myrem(17);
    }

    @include mq("sm"){
        font-size: myrem(25);
        display: flex;
        align-items: flex-end;
    }
}


.p-solution-item__img{
    margin-top: myrem(-58);
    padding: 0 myrem(16) 0 myrem(16);

    @include mq("sm"){
        margin-top: myrem(-30);
    }
}

.p-solution-item.p-solution-item--dx .p-solution-item__img{

    @include mq("sm"){
    // transform: translateY(myrem(-110));
        margin-top: myrem(-110);
    }
}

.p-solution-item.p-solution-item--cx .p-solution-item__img{
    padding: 0 myrem(6) 0 myrem(49);
    transform: translateY(myrem(25));

    @include mq("sm"){
        margin-top: myrem(-45);
        transform: initial;
    // transform: translateY(myrem(-15));

        padding: 0 myrem(6) 0 myrem(90);
    }
}

.p-solution-item.p-solution-item--ex .p-solution-item__img{
    padding: 0 myrem(11) 0 myrem(90);
    transform: translateY(myrem(20));

    @include mq("sm"){
        transform: initial;
        margin-top: myrem(-50);
        padding: 0 myrem(6) 0 myrem(130);
    }
}

.p-solution-item__img img{
    width: 100%;
}


.p-solution-item__text {
    margin-top: myrem(9);
    font-size: myrem(16);
    font-weight: $regular;
    line-height:  math.div(28, 16);
    text-align: left;    

    @include mq("md"){
        font-size: myrem($sp-base-fs);
    }
}

.p-solution-item__lists{
    margin-top: myrem(19);
}

.p-solution-item__list{
    display: flex;
    align-items: center;
    position: relative;
    font-size: myrem(18);
    letter-spacing: 0.05em;
    line-height:  math.div(28, 18);
    font-weight: $bold;
    text-align: left;

    @include mq("md"){
        font-size: myrem($sp-base-fs);
    }
}

.p-solution-item__list::before{
    content:  "";     /* 空の要素作成 */
    position: absolute;
    width:  myrem(15);               /* 幅指定 */
    height:  myrem(15);              /* 高さ指定 */
    display:  inline-block;     /* インラインブロックにする */
    background-color: $deepblue;  /* 背景色指定 */
    border-radius:  50%;        /* 要素を丸くする */
    position:  relative;        /* 位置調整 */
    top: myrem(9);                  /* 位置調整 */
    transform: translateY(-50%);
    margin-right: 0.5rem;          /* 余白指定 */
}

.p-solution-item__btn{
    margin-top: myrem(26);
    padding-left: myrem(17);
    padding-right: myrem(17);

    @include mq("sm"){
        margin-top: myrem(50);
    }
}