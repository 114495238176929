@use "global" as *;
@use "sass:math";

.p-develop__subttl{
    font-size: myrem(20);
    margin-top: myrem(15);
    color: $textblue;

    @include mq("sm"){
        font-size: myrem(22);
        
        text-align: left;
    }
}

.p-develop__main{
    margin-top: myrem(53);
}

.p-dev-swiper__bg{
    overflow: hidden;
    position: relative;
    padding-bottom: myrem(132);
}

.p-dev-swiper__bg::before{
    position: absolute;
    // z-index: ;
    top: myrem(-19);
    left: calc(50% + myrem(29));
    content: "";
    display: block;
    background-image: url("/img/2024/develop-phone.png");
    background-size:100%;
    width: myrem(1131);
    height: myrem(872.89);

    @include mq("sm"){
      transform: translate( -50%, 0);
      top: initial;
      bottom: myrem(0);
      left: calc(50% + myrem(87));
    }
}

.p-dev-swiper__bgcolor{
    background-color: #D7E4EC;
}

.p-dev-swiper__inner{
    max-width: myrem(1315);
    margin: 0 auto;
    padding: myrem(93) myrem(25) myrem(87);

    @include mq("sm"){
      padding-top: myrem(45);
    }
}

.p-dev-swiper__main{
    display: flex;
    justify-content: space-between;
    position: relative;

    @include mq("sm"){
        flex-direction: column;
        align-items: center;
        gap: myrem(80);
    }
}

.p-dev-swiper__admin{
    position: relative;
    width: myrem(886);
    aspect-ratio: 886 / 540;
    object-fit: cover;

    @include mq("sm"){
      width: 100%;
    }
}
.p-dev-swiper__admin::after{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: myrem(-70);
    transform: translateY(-50%);
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    width: myrem(23);
    height: myrem(52);
    background-color: #002366;

    @include mq("sm"){
        width: myrem(52);
        height: myrem(23);
        clip-path: polygon(0 0, 50% 100%, 100% 0%);
        left: 50%;   
        transform: translateX(-50%);
        top: calc(100% + myrem(20));
    }

}

.p-dev-swiper__sp{
    border-radius:myrem(25);
    overflow: hidden;
    aspect-ratio: 250 / 540;
    width: myrem(250);
    object-fit: cover;
    background-color: #000000;

    @include mq("sm"){
      width: myrem(250);
    }
}


.p-dev-swiper .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: myrem(-55);

    @include mq("sm"){
      bottom: myrem(-80);
    }
}

.p-dev-swiper .swiper-pagination-bullet{

    @include mq("sm"){      
        background-color: $white;
        opacity: .9;
    }
}
.p-dev-swiper .swiper-pagination-bullet-active{
    background-color:#002366;
}