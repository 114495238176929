@use "global" as *;
@use "sass:math";

.c-accordion {
    position: relative;
    width: 100%;
    color: $textblue;
}
.c-accordion__item {
    // padding-bottom: myrem(16);
    border-bottom: 1px solid #cdd6dd;
}

.c-accordion__item:not(:first-child) {
    margin-top: myrem(26);
}

.c-accordion__ttl {
    padding-bottom: myrem(16);
    padding-top: myrem(16);

    cursor: pointer;
    font-size: myrem(18);
    letter-spacing: 0.05em;
    line-height: math.div(28, 18);
    font-weight: $medium;
    text-align: left;
    position: relative;
    padding-left: myrem(40);
    padding-right: myrem(40);
    transition: opacity $base-anime-duration;

    @include mq("sm") {
        font-size: myrem(22);
    }
}

.c-accordion__ttl:hover {
    opacity: 0.4;
}

.c-accordion__ttl::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: myrem(32);
    height: myrem(32);
    background-image: url("/img/2024/faq-icon.svg");
    background-size: cover;
}

.c-accordion__text {
    padding-left: myrem(45);
    padding-right: myrem(40);
    padding-bottom: myrem(22);
    margin-top: myrem(13);
    display: none;
    text-align: left;
}

.c-accordion__text p {
    font-size: myrem(16);
    line-height: math.div(28, 16);
    letter-spacing: 0.05em;

    @include mq("sm") {
        font-size: myrem(20);
    }
}

/* 矢印 */
.c-accordion__ttl::after {
    border-right: solid 2px $textblue;
    border-top: solid 2px $textblue;
    content: "";
    display: block;
    height: myrem(12);
    width: myrem(12);
    position: absolute;
    right: myrem(2);
    top: calc(50% - myrem(7));
    transform: rotate(135deg);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.c-accordion__ttl.is-open::after {
    top: 50%;
    transform: rotate(-45deg);
}
