@use "global" as *;
@use "sass:math";

.p-banner__inner.l-inner{
    @include mq("md"){
        padding: 0 myrem(40);
    }
}

.p-banner__ttl{
    font-size: myrem(30);
    font-weight: $bold;
    letter-spacing: 0.05em;
    color: $textblue;
    line-height:  math.div(56, 28);

    @include mq("sm"){
        font-size: myrem(28);
        text-align: left;
    }

}

.p-banner__items {
    margin-top: myrem(49);
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: myrem(41);
    max-width: myrem(1165);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    list-style:none;

    @include mq("sm"){
        // grid-template-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        // max-width: myrem(500);
        max-width: 100%;
        gap: myrem(30);
    }
}



.p-banner__item {
    aspect-ratio: 160 / 50;
    list-style:none;
}

.p-banner__btn{
    margin-top: myrem(54);
}
