@charset "UTF-8";
/* リキッドレイアウト対応 */
.u-desktop {
  display: none;
}
@media screen and (max-width: 599px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (max-width: 599px) {
  .u-mobile {
    display: none;
  }
}

/* =====================================================
ブロックエディタ css
===================================================== */
.wp-block-style {
  margin-top: 2.5rem;
  /* リンク */
  /* 太字 */
  /* リスト 位置調整 */
  /**************************************
  * グーテンベルクの画像幅調整
  * ***********************************/
}
@media screen and (max-width: 599px) {
  .wp-block-style {
    margin-top: 1.25rem;
  }
}
.wp-block-style * {
  margin-top: 15px;
  clear: both;
  font-family: "Noto Sans JP", sans-serif;
}
.wp-block-style .wp-block-style__end {
  display: block;
  margin: 0;
}
.wp-block-style a {
  color: #00e;
  text-decoration: underline;
}
.wp-block-style a:visited {
  color: #551a8b;
  text-decoration: underline;
}
.wp-block-style strong {
  font-weight: bold;
}
.wp-block-style li {
  list-style: initial;
}
.wp-block-style ul,
.wp-block-style ol {
  padding-left: 20px;
}
.wp-block-style ul li,
.wp-block-style ol li {
  margin-top: 0px;
  line-height: 1.8666666667;
}
.wp-block-style ul ol,
.wp-block-style ul ul,
.wp-block-style ol ol,
.wp-block-style ol ul {
  margin-top: 0rem;
}
.wp-block-style .wp-block-file .wp-block-file__button {
  padding: 4px 16px;
  color: #fff;
  text-decoration: initial;
}
.wp-block-style selector {
  list-style-type: lower-alpha;
}
.wp-block-style h2 {
  border-left: 2px solid #0459e2;
  padding-left: 0.75rem;
  font-size: 1.5rem;
}
.wp-block-style .wp-block-image img {
  height: auto !important;
}
.wp-block-style .wp-block-image figure {
  float: initial !important;
}

.c-accordion {
  position: relative;
  width: 100%;
  color: #002366;
}

.c-accordion__item {
  border-bottom: 1px solid #cdd6dd;
}

.c-accordion__item:not(:first-child) {
  margin-top: 1.3rem;
}

.c-accordion__ttl {
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  cursor: pointer;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  line-height: 1.5555555556;
  font-weight: 500;
  text-align: left;
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  transition: opacity 0.3s;
}
@media screen and (max-width: 599px) {
  .c-accordion__ttl {
    font-size: 1.1rem;
  }
}

.c-accordion__ttl:hover {
  opacity: 0.4;
}

.c-accordion__ttl::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 1.6rem;
  height: 1.6rem;
  background-image: url("/img/2024/faq-icon.svg");
  background-size: cover;
}

.c-accordion__text {
  padding-left: 2.25rem;
  padding-right: 2rem;
  padding-bottom: 1.1rem;
  margin-top: 0.65rem;
  display: none;
  text-align: left;
}

.c-accordion__text p {
  font-size: 0.8rem;
  line-height: 1.75;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 599px) {
  .c-accordion__text p {
    font-size: 1rem;
  }
}

/* 矢印 */
.c-accordion__ttl::after {
  border-right: solid 2px #002366;
  border-top: solid 2px #002366;
  content: "";
  display: block;
  height: 0.6rem;
  width: 0.6rem;
  position: absolute;
  right: 0.1rem;
  top: calc(50% - 0.35rem);
  transform: rotate(135deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}

.c-accordion__ttl.is-open::after {
  top: 50%;
  transform: rotate(-45deg);
}

.c-base-btn {
  display: inline-flex;
  border-radius: 2rem;
  background-color: #003398;
  transition: all 0.2s;
  width: 14.9rem;
}
@media screen and (max-width: 599px) {
  .c-base-btn {
    width: 80%;
  }
}

.c-base-btn a {
  position: relative;
  width: 100%;
  padding: 0.8055rem 0.4rem;
  font-size: 0.8rem;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
}
@media screen and (max-width: 782px) {
  .c-base-btn a {
    font-size: 1.2rem;
  }
}

.c-base-btn a span {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #0459e2;
  top: 50%;
  right: 0.9rem;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: all 0.2s;
}

.c-base-btn a span::before,
.c-base-btn a span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.4rem;
  height: 0.4rem;
  background-color: #ffffff;
  border-radius: 50%;
  transition: all 0.2s;
}

/* アニメーション */
.c-base-btn:hover {
  background-color: #0459e2;
}

.c-base-btn:hover a span {
  background-color: #ffffff;
}

.c-base-btn:hover a span::before,
.c-base-btn:hover a span::after {
  border-radius: initial;
  width: 0.35rem;
  height: 0.075rem;
  background-color: #0459e2;
}
@media screen and (max-width: 599px) {
  .c-base-btn:hover a span::before,
.c-base-btn:hover a span::after {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
  }
}

.c-base-btn:hover a span::before {
  transform: translate(calc(-50% + 0.05rem), calc(-50% - 0.1rem)) rotate(40deg);
}
@media screen and (max-width: 599px) {
  .c-base-btn:hover a span::before {
    transform: translate(-50%, -50%);
  }
}

.c-base-btn:hover a span::after {
  transform: translate(calc(-50% + 0.05rem), calc(-50% + 0.1rem)) rotate(-40deg);
}
@media screen and (max-width: 599px) {
  .c-base-btn:hover a span::after {
    transform: translate(-50%, -50%);
  }
}

.c-base-btn.c-base-btn--green {
  background-color: #04986A;
}

.c-base-btn.c-base-btn--green a span {
  background-color: #59C100;
}

.c-base-btn.c-base-btn--green:hover {
  background-color: #59C100;
}

.c-base-btn.c-base-btn--green:hover a span {
  background-color: #ffffff;
}

.c-base-btn.c-base-btn--green:hover a span::before,
.c-base-btn.c-base-btn--green:hover a span::after {
  background-color: #59C100;
}

.c-base-btn.c-base-btn--small {
  width: 12.6rem;
  font-size: 0.7rem;
}
@media screen and (max-width: 782px) {
  .c-base-btn.c-base-btn--small {
    font-size: 1.2rem;
    width: 15rem;
  }
}
@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--small {
    width: 80%;
  }
}

.c-base-btn.c-base-btn--small a {
  font-size: 0.7rem;
}
@media screen and (max-width: 782px) {
  .c-base-btn.c-base-btn--small a {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--small a {
    font-size: 1rem;
    padding: 0.725rem 1.9rem 0.725rem 1rem;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--small:hover a {
    color: #ffffff;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--small:hover a span {
    background-color: #ffffff;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--small:hover a span::before,
.c-base-btn.c-base-btn--small:hover a span::after {
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 50%;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--small:hover a span::before {
    transform: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--small:hover a span::after {
    transform: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-base-btn.c-base-btn--mv {
  width: 17.3rem;
}
@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv {
    width: 85%;
  }
}

.c-base-btn.c-base-btn--mv a {
  padding: 0.925rem 2.25rem;
  font-size: 1.1rem;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv a {
    font-size: 1rem;
    padding: 0.725rem 1.9rem 0.725rem 1rem;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--sp-white {
    background-color: #ffffff;
    border: 1px solid #002366;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--sp-white a {
    color: #002366;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--sp-white a span {
    background-color: #0459e2;
  }
}

.c-base-btn.c-base-btn--mv a span {
  width: 2rem;
  height: 2rem;
  right: 0.9rem;
}
@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv a span {
    width: 1.1rem;
    height: 1.1rem;
  }
}

.c-base-btn.c-base-btn--mv a span::before,
.c-base-btn.c-base-btn--mv a span::after {
  left: calc(50% - 0.01rem);
  width: 0.5rem;
  height: 0.5rem;
}
@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv a span::before,
.c-base-btn.c-base-btn--mv a span::after {
    width: 0.3rem;
    height: 0.3rem;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--sp-white:hover {
    background-color: #002366;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv:hover a {
    color: #ffffff;
  }
}

@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv:hover a span {
    background-color: #ffffff;
  }
}

.c-base-btn.c-base-btn--mv:hover a span::before,
.c-base-btn.c-base-btn--mv:hover a span::after {
  width: 0.5rem;
  height: 0.1rem;
}
@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv:hover a span::before,
.c-base-btn.c-base-btn--mv:hover a span::after {
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 50%;
  }
}

.c-base-btn.c-base-btn--mv:hover a span::before {
  transform: translate(calc(-50% + 0.05rem), calc(-50% - 0.15rem)) rotate(40deg);
}
@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv:hover a span::before {
    transform: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-base-btn.c-base-btn--mv:hover a span::after {
  transform: translate(calc(-50% + 0.05rem), calc(-50% + 0.15rem)) rotate(-40deg);
}
@media screen and (max-width: 599px) {
  .c-base-btn.c-base-btn--mv:hover a span::after {
    transform: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-breadcrumb {
  font-size: 0.45rem;
}
@media screen and (max-width: 782px) {
  .c-breadcrumb {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 599px) {
  .c-breadcrumb {
    font-size: 0.8rem;
  }
}

.c-breadcrumbs__inner {
  padding: 0 1.1rem;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 599px) {
  .c-breadcrumbs__inner {
    padding: 0 0.5rem;
  }
}

.c-breadcrumbs__main span {
  font-size: 0.6rem;
  font-weight: 400;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 782px) {
  .c-breadcrumbs__main span {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 599px) {
  .c-breadcrumbs__main span {
    font-size: 1rem;
  }
}

.c-breadcrumbs__main .home,
.c-breadcrumbs__main {
  color: #002366;
}

.c-breadcrumbs__main > span {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.c-breadcrumbs__main span.current-item {
  text-decoration: underline;
  text-decoration-color: #002366;
}

.p-contents__inner.l-inner {
  max-width: 55.9rem;
}

.p-contents__items {
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
}

.c-content {
  color: #002366;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 599px) {
  .c-content {
    align-items: center;
    flex-direction: column;
  }
}

.c-content.c-content--top {
  align-items: flex-start;
}
@media screen and (max-width: 599px) {
  .c-content.c-content--top {
    align-items: flex-start;
  }
}

.c-content:nth-child(even) {
  flex-direction: row-reverse;
}
@media screen and (max-width: 599px) {
  .c-content:nth-child(even) {
    flex-direction: column;
  }
}

.c-content__info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 24rem;
  text-align: left;
}
@media screen and (max-width: 599px) {
  .c-content__info {
    max-width: 100%;
  }
}

.c-content__ttl {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 2.0625;
  border-bottom: 1px solid #cdd6dd;
}
@media screen and (max-width: 599px) {
  .c-content__ttl {
    border-bottom: 3px solid #cdd6dd;
    font-size: 1.7rem;
  }
}

.c-content__subttl.c-content__subttl--large {
  font-size: 1.4rem;
  line-height: 1.4285714286;
}

.c-content__subttl {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.5;
}
@media screen and (max-width: 599px) {
  .c-content__subttl {
    font-size: 1.4rem;
  }
}

.c-content__text {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
}
@media screen and (max-width: 599px) {
  .c-content__text {
    font-size: 1.2rem;
  }
}

.c-content__img {
  aspect-ratio: 480/270;
  width: 24rem;
}
@media screen and (max-width: 599px) {
  .c-content__img {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;
  }
}

.c-content__cats {
  margin-top: 0.25rem;
}

.c-cats-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.2rem;
  width: 100%;
}
.c-cats-grid.c-cats-grid--product {
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.c-cats-grid__item {
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #002366;
  background-color: #cee2ff;
  text-align: center;
  padding: 0.205rem 0.5rem;
}

.c-cats-grid.c-cats-grid--product .c-cats-grid__item {
  padding: 0.28rem 0.1rem;
}

@media screen and (max-width: 599px) {
  .c-content.c-content--bumon .c-content__img {
    width: 100%;
  }
}

.p-contents__flow-arrow {
  margin-top: 0.25rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #d7e4ec;
  aspect-ratio: 88/28;
  width: 4.4rem;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}
@media screen and (max-width: 599px) {
  .p-contents__flow-arrow {
    margin-top: 0.75rem;
  }
}

.c-dx {
  color: #002366;
}

.c-dx__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 599px) {
  .c-dx__top {
    flex-direction: column;
  }
}

.c-dx__left {
  text-align: left;
}
@media screen and (max-width: 599px) {
  .c-dx__left {
    width: 100%;
  }
}

.c-dx__ttl {
  font-size: 2.4rem;
  font-weight: 500;
  margin-top: 1.6rem;
  line-height: 1;
}
@media screen and (max-width: 782px) {
  .c-dx__ttl {
    color: #0459e2;
    margin-top: initial;
  }
}

.c-dx__ttl span {
  position: relative;
  top: 0.2rem;
  margin-left: 0.25rem;
  font-size: 2.9rem;
  font-family: "Montserrat", sans-serif;
}

.c-dx__subttl {
  margin-top: 2rem;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  line-height: 1.4285714286;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .c-dx__subttl {
    margin-top: 1rem;
  }
}

.c-dx__text {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
}
@media screen and (max-width: 599px) {
  .c-dx__text {
    font-size: 1.1rem;
  }
}

.c-dx__img {
  width: 26rem;
}
@media screen and (max-width: 599px) {
  .c-dx__img {
    width: 100%;
    margin-top: 1rem;
  }
}

.c-dx__items {
  margin-top: 2.4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.4rem;
  column-gap: 4.9rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 599px) {
  .c-dx__items {
    grid-template-columns: 1fr;
    max-width: 40rem;
    row-gap: 2.1rem;
  }
}

.c-dx__item {
  /*グリットアイテムを3グリット分の大きさにする*/
  grid-row: span 4;
  /*サブグリットの指定*/
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  width: 100%;
  text-align: left;
  color: #002366;
}

.c-dx__item-ttl {
  font-size: 1.1rem;
  line-height: 1.4545454545;
  letter-spacing: 0.05em;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .c-dx__item-ttl {
    font-size: 1.3rem;
  }
}

.c-dx__item-img {
  margin-top: 0.5rem;
  aspect-ratio: 280/112;
  width: 100%;
}

.c-dx__item-subttl {
  margin-top: 0.65rem;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.4705882353;
}
@media screen and (max-width: 599px) {
  .c-dx__item-subttl {
    font-size: 1rem;
  }
}

.c-dx__item-text {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  line-height: 1.75;
  font-weight: 400;
}
@media screen and (max-width: 599px) {
  .c-dx__item-text {
    font-size: 1rem;
  }
}

.c-items__top-ttl {
  font-size: 1.1rem;
  line-height: 1.6363636364;
  letter-spacing: 0.05em;
  font-weight: 700;
  color: #002366;
  background-color: #eef1f7;
  padding-top: 0.25rem;
  padding-bottom: 5px;
}

.c-items__main {
  margin-top: 2.8rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 3rem;
  column-gap: 3.1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #002366;
}
@media screen and (max-width: 599px) {
  .c-items__main {
    margin-top: 1.5rem;
    grid-template-columns: 1fr;
    max-width: 100%;
  }
}

.c-items__item {
  /*グリットアイテムを3グリット分の大きさにする*/
  /*サブグリットの指定*/
  width: 100%;
  text-align: center;
}

.c-items__img {
  aspect-ratio: 344/220;
  width: 100%;
  height: auto;
}
.c-items__ttl {
  margin-top: 0.4rem;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  line-height: 1.4444444444;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .c-items__ttl {
    margin-top: 0.5rem;
    font-size: 1.1rem;
  }
}

.c-items__ttl > span {
  font-size: 0.8rem;
}

.c-items__text {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.75;
  margin-top: 0.4rem;
}
@media screen and (max-width: 599px) {
  .c-items__text {
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }
}

.c-news {
  display: flex;
  flex-direction: column;
  color: #002366;
}

.c-news__info {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 599px) {
  .c-news__info {
    justify-content: flex-start;
    width: 100%;
  }
}

.c-news__bottom {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 599px) {
  .c-news__bottom {
    margin-top: 0.5rem;
  }
}

.c-news__imgs {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.c-news__item {
  border-top: 1px solid #cdd6dd;
}

.c-news__item:last-child {
  border-bottom: 1px solid #cdd6dd;
}

.c-news__link {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0.75rem 0;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.3s;
}
@media screen and (max-width: 599px) {
  .c-news__link {
    flex-direction: column;
  }
}

.c-news__link:hover {
  opacity: 0.2;
}

.c-news__time {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 599px) {
  .c-news__time {
    font-size: 0.9rem;
  }
}

.c-news__cats {
  margin-left: 2.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 6rem;
}
@media screen and (max-width: 599px) {
  .c-news__cats {
    flex-direction: row;
    width: 100%;
  }
}

.c-news__cats li {
  width: 100%;
  line-height: 1.2;
  padding: 0.3335rem;
  background-color: #cee2ff;
  border-radius: 0.5rem;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 599px) {
  .c-news__cats li {
    font-size: 0.8rem;
    width: 6rem;
  }
}

.c-news__ttl {
  margin-left: 2.6rem;
  max-width: 22.8rem;
  display: block;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.75;
  width: 100%;
}
@media screen and (max-width: 599px) {
  .c-news__ttl {
    margin-left: initial;
    max-width: initial;
    font-size: 1rem;
  }
}

.c-news__img {
  aspect-ratio: 182.66/93;
  width: 9.133rem;
  object-fit: cover;
}
@media screen and (max-width: 599px) {
  .c-news__img {
    display: none;
  }
}

.c-news__news-icon {
  width: 0.8rem;
}

.c-page-catch {
  color: #002366;
}

.c-page-catch__main {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 599px) {
  .c-page-catch__main {
    font-size: 1.6rem;
    text-align: left;
  }
}

.c-page-catch__sub {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
}
@media screen and (max-width: 599px) {
  .c-page-catch__sub {
    font-size: 1.2rem;
    text-align: left;
  }
}

.c-page-catch__img {
  margin-top: 1.15rem;
}
@media screen and (max-width: 599px) {
  .c-page-catch__img {
    width: 100%;
  }
}

.c-page-catch__img.c-page-catch__img--cx {
  width: 40rem;
}

.c-page-catch__img.c-page-catch__img--ex {
  width: 34.05rem;
}

/* =====================================================
pagination CSS ページナビ　WP pagenavi
===================================================== */
.c-pagination .wp-pagenavi {
  text-align: center;
  position: relative;
  /* その他、必要があれば以下のクラスにcssを指定する */
}
.c-pagination .wp-pagenavi .pages {
  display: none;
}
.c-pagination .wp-pagenavi span,
.c-pagination .wp-pagenavi a {
  /* 数字部分の共通CSS　大きさなど */
  margin: 0 0.35rem;
  font-weight: 400;
  line-height: 1;
  color: #a0a6aa;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  width: 3rem;
  border-radius: 50%;
  font-size: 0.9rem;
  border: 1px solid transparent;
  transition: border 0.1s;
}
@media screen and (max-width: 599px) {
  .c-pagination .wp-pagenavi span,
.c-pagination .wp-pagenavi a {
    width: 2.5rem;
  }
}
.c-pagination .wp-pagenavi span.current {
  color: #ffffff;
  background-color: #0459e2;
}
.c-pagination .wp-pagenavi a:hover {
  /* 現在のページ */
  border: 1px solid #0459e2;
}
.c-pagination .wp-pagenavi .previouspostslink {
  color: transparent;
  background: url(/img/2024/arrow_left.svg) no-repeat center center;
  background-size: 6px auto;
}
.c-pagination .wp-pagenavi .nextpostslink {
  color: transparent;
  background: url(/img/2024/arrow_right.svg) no-repeat center center;
  background-size: 6px auto;
}
.c-pagination .wp-pagenavi .first,
.c-pagination .wp-pagenavi .last {
  display: none;
}
.c-pagination .wp-pagenavi .extend:last-of-type {
  display: none;
}

.c-posts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.4rem;
  max-width: 57.35rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 782px) {
  .c-posts {
    grid-template-columns: 1fr;
    max-width: 40rem;
    row-gap: 2rem;
  }
}
.c-posts__item {
  /*グリットアイテムを3グリット分の大きさにする*/
  grid-row: span 2;
  /*サブグリットの指定*/
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  width: 100%;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 782px) {
  .c-posts__item {
    border: none;
  }
}

.c-posts__item:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  border-right: 1px dashed #ffffff;
}
@media screen and (max-width: 782px) {
  .c-posts__item:after {
    border: initial;
  }
}

.c-posts__item:nth-child(3n+1)::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  border-left: 1px dashed #ffffff;
}
@media screen and (max-width: 782px) {
  .c-posts__item:nth-child(3n+1)::before {
    border: none;
  }
}

.c-posts__item {
  padding-top: 2.3rem;
  padding-bottom: 2rem;
}
@media screen and (max-width: 782px) {
  .c-posts__item {
    padding-top: 1.5rem;
    padding-bottom: 2.25rem;
  }
}

.c-posts__item > * {
  padding-left: 2.15rem;
  padding-right: 2.15rem;
}
@media screen and (max-width: 599px) {
  .c-posts__item > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.c-posts__item-img {
  position: relative;
}

.c-posts__item-img > img {
  border-radius: 0.55rem;
  aspect-ratio: 297/200;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.c-posts__item-logo {
  position: absolute;
  background-color: #ffffff;
  bottom: 0;
  left: 2.15rem;
  width: 7.4rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 0.5rem;
}

.c-posts__item-logo > img {
  padding: 0 0.25rem;
  display: block;
  object-fit: contain;
  width: auto;
  height: 100%;
}

.c-posts__item-cats ul {
  margin-top: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.c-posts__item-cats ul li {
  display: block;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.1;
  color: #002366;
  background-color: #ffffff;
  border: 1px solid #002366;
  padding: 0.225rem 0.5rem;
}
@media screen and (max-width: 782px) {
  .c-posts__item-cats ul li {
    font-size: 0.95rem;
  }
}

.c-posts__item-title {
  margin-top: 0.5rem;
  text-align: left;
}

.c-posts__item-title {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  color: #002366;
  transition: color 0.1s;
  line-height: 1.75;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 782px) {
  .c-posts__item-title {
    font-size: 1.2rem;
  }
}

.c-posts__item {
  border-radius: 1rem;
  transition: background-color 0.3s;
}

.c-posts__item:hover {
  background-color: #BCD3F2;
}

@media screen and (max-width: 782px) {
  .c-posts__item {
    background-color: #BCD3F2;
  }
}

.c-posts.c-posts__tenpolab .c-posts__item:hover {
  background-color: #C1CDD5;
}

@media screen and (max-width: 782px) {
  .c-posts.c-posts__tenpolab .c-posts__item {
    background-color: #C1CDD5;
  }
}

.c-prices {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.6rem;
  column-gap: 1.6rem;
  max-width: 55.1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 599px) {
  .c-prices {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
}

.c-prices__item {
  grid-row: span 2;
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  width: 100%;
}

.c-price {
  padding: 2.4rem 0 2rem;
  background-color: #727880;
  text-align: center;
  border-radius: 0.35rem;
  color: #ffffff;
}

.c-price > * {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.c-price__ttl-en {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #a0a6aa;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  padding: 0.3rem 0 0.2rem;
}
@media screen and (max-width: 599px) {
  .c-price__ttl-en {
    font-size: 1.2rem;
    padding: 0.6rem 0 0.4rem;
  }
}

.c-price__ttl-ja {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  border: 0.1rem solid #ffffff;
  padding: 0.7rem 0;
}
@media screen and (max-width: 599px) {
  .c-price__ttl-ja {
    font-size: 1.4rem;
    padding: 1rem 0;
  }
}

.c-price__text {
  margin-top: 1.5rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
}
@media screen and (max-width: 782px) {
  .c-price__text {
    font-size: 1.2rem;
  }
}

.c-price__price {
  margin-top: 1.25rem;
  font-size: 0.9rem;
  font-weight: 500;
}
@media screen and (max-width: 782px) {
  .c-price__price {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 599px) {
  .c-price__price {
    font-size: 1.5rem;
  }
}

.c-price__price span {
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
@media screen and (max-width: 782px) {
  .c-price__price span {
    font-size: 2rem;
  }
}

.c-price.c-price--blue {
  background-color: #0459e2;
}

.c-price.c-price--blue .c-price__ttl-en {
  color: #0459e2;
}

.c-price.c-price--brown {
  background-color: #002366;
}

.c-price.c-price--brown .c-price__ttl-en {
  color: #002366;
  background-color: #cc9d4b;
}

.c-price.c-price--brown .c-price__ttl-ja {
  color: #cc9d4b;
  border: 0.1rem solid #cc9d4b;
}

.c-product-4f__inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 51.75rem;
}

.c-product-4f__items {
  margin-top: 1.85rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 3rem;
  column-gap: 2.25rem;
  color: #002366;
}
@media screen and (max-width: 599px) {
  .c-product-4f__items {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
}

.c-product-4f__top-ttl {
  display: flex;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.4583333333;
  color: #002366;
  text-align: left;
  position: relative;
  padding-left: 0.95rem;
}
@media screen and (max-width: 599px) {
  .c-product-4f__top-ttl {
    font-size: 1.6rem;
  }
}

.c-product-4f__top-ttl:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: block;
  width: 0.1rem;
  height: 80%;
  background-color: #002366;
}

.c-product-4f__item {
  /*グリットアイテムを3グリット分の大きさにする*/
  grid-row: span 3;
  /*サブグリットの指定*/
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  width: 100%;
  text-align: center;
  justify-items: center;
}

.c-product-4f__img {
  width: 89%;
}
@media screen and (max-width: 599px) {
  .c-product-4f__img {
    width: 60%;
  }
}

.c-product-4f__ttl {
  margin-top: 0.75rem;
  font-size: 1.1rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .c-product-4f__ttl {
    margin-top: 1rem;
    font-size: 1.4rem;
  }
}

.c-product-4f__text {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.75;
  margin-top: 0.5rem;
}
@media screen and (max-width: 599px) {
  .c-product-4f__text {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }
}

.c-product {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 3rem;
  column-gap: 3.1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #002366;
}
@media screen and (max-width: 599px) {
  .c-product {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
}

.c-product__item {
  /*グリットアイテムを3グリット分の大きさにする*/
  grid-row: span 3;
  /*サブグリットの指定*/
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  width: 100%;
  text-align: center;
  justify-items: center;
}

.c-product__img {
  width: 76.31578947%;
}
@media screen and (max-width: 599px) {
  .c-product__img {
    width: 60%;
  }
}

.c-product__img.c-product__img--center {
  width: 100%;
  align-self: center;
}

.c-product__ttl {
  margin-top: 0.75rem;
  font-size: 1.1rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .c-product__ttl {
    margin-top: 1rem;
    font-size: 1.4rem;
  }
}

.c-product__ttl > span {
  font-size: 0.8rem;
}

.c-product__text {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.75;
  margin-top: 0.5rem;
}
@media screen and (max-width: 599px) {
  .c-product__text {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }
}

.c-secttl__text {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #002366;
  text-align: center;
  line-height: 1.4375;
}
@media screen and (max-width: 599px) {
  .c-secttl__text {
    text-align: left;
  }
}

.c-secttl.c-secttl--large .c-secttl__text {
  font-size: 2rem;
  letter-spacing: 0.05em;
  line-height: 1.45;
}

.c-secttl.c-secttl--xlarge .c-secttl__text {
  font-size: 2.4rem;
  letter-spacing: 0.05em;
  line-height: 1.6666666667;
}

@media screen and (max-width: 599px) {
  .c-secttl.c-secttl--price .c-secttl__text {
    text-align: center;
  }
}

.c-secttl__text .small {
  font-size: 1.6rem;
}

@media screen and (max-width: 782px) {
  .p-banner__inner.l-inner {
    padding: 0 2rem;
  }
}

.p-banner__ttl {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #002366;
  line-height: 2;
}
@media screen and (max-width: 599px) {
  .p-banner__ttl {
    font-size: 1.4rem;
    text-align: left;
  }
}

.p-banner__items {
  margin-top: 2.45rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2.05rem;
  max-width: 58.25rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  list-style: none;
}
@media screen and (max-width: 599px) {
  .p-banner__items {
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    gap: 1.5rem;
  }
}

.p-banner__item {
  aspect-ratio: 160/50;
  list-style: none;
}

.p-banner__btn {
  margin-top: 2.7rem;
}

.p-bumon-anchor__inner.l-inner {
  max-width: 49.3rem;
}
@media screen and (max-width: 599px) {
  .p-bumon-anchor__inner.l-inner {
    padding: 0 0.5rem;
  }
}

.p-bumon-anchor__items {
  display: flex;
  align-items: center;
  gap: 9.9rem;
}
@media screen and (max-width: 599px) {
  .p-bumon-anchor__items {
    gap: 0.5rem;
    justify-content: space-between;
  }
}

.p-bumon-anchor__item {
  flex: 1;
  transition: border-bottom-color 0.2s, opacity 0.2s;
  border-bottom: 4px solid transparent;
}

.p-bumon-anchor__item:hover {
  opacity: 0.8;
  border-bottom-color: #002366;
}

.p-bumon-anchor__img {
  width: 7.3rem;
  height: 7.3rem;
}
@media screen and (max-width: 599px) {
  .p-bumon-anchor__img {
    width: 6rem;
    height: 6rem;
  }
}

.p-bumon-anchor__ttl {
  margin-top: 0.4rem;
  color: #002366;
  font-size: 0.9rem;
  line-height: 1.4444444444;
  font-weight: 700;
  text-align: center;
}

.p-bumon-anchor__arrow {
  transform: translateY(-0.2rem);
}

.c-circle-arrow {
  position: relative;
  display: inline-block;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  background-color: #003398;
}

.c-circle-arrow::before,
.c-circle-arrow::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center bottom;
  width: 2px;
  height: 0.35rem;
  border-radius: 0.1rem;
  background-color: #ffffff;
}

.c-circle-arrow::before {
  transform: translate(calc(-50% + 0.025rem), -50%) rotate(-45deg);
}

.c-circle-arrow::after {
  transform: translate(calc(-50% - 0.025rem), -50%) rotate(45deg);
}

.p-bumon__items {
  display: grid;
  margin-top: 2.45rem;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.6rem;
  column-gap: 1.6rem;
  max-width: 55.1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 599px) {
  .p-bumon__items {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
}

.p-bumon__item {
  /*グリットアイテムを3グリット分の大きさにする*/
  grid-row: span 3;
  /*サブグリットの指定*/
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  width: 100%;
}

.p-bumon__btn {
  margin-top: 2.25rem;
}

.p-bumon-item {
  padding: 1.15rem 0 1.35rem;
  background-color: #ffffff;
  text-align: center;
  border-radius: 0.35rem;
  color: #002366;
  border: 1px solid #002366;
}
@media screen and (max-width: 599px) {
  .p-bumon-item {
    padding: 1.75rem 0 1.6rem;
  }
}

.p-bumon-item > * {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
@media screen and (max-width: 599px) {
  .p-bumon-item > * {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

.p-bumon-item__img img {
  aspect-ratio: 152/159;
  width: 7.6rem;
}
@media screen and (max-width: 599px) {
  .p-bumon-item__img img {
    width: 9rem;
  }
}

.p-bumon-item__ttl {
  margin-top: 0.3rem;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .p-bumon-item__ttl {
    font-size: 1.5rem;
    margin-top: 0.6rem;
  }
}

.p-bumon-item__text {
  margin-top: 0.15rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
}
@media screen and (max-width: 599px) {
  .p-bumon-item__text {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 599px) {
  .p-consult {
    overflow: hidden;
  }
}

.p-consult__inner.l-inner {
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  max-width: 55rem;
}
@media screen and (max-width: 599px) {
  .p-consult__inner.l-inner {
    max-width: 100%;
    padding-bottom: 0.75rem;
  }
}

.p-consult__ttl {
  color: #0459e2;
  font-size: 2.1rem;
  font-weight: 700;
  text-align: center;
}

.p-consult__text {
  margin-top: 1rem;
  line-height: 1.5;
  font-size: 0.8rem;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .p-consult__text {
    text-align: left;
    margin-top: 1.5rem;
    font-size: 1.2rem;
    line-height: 1.7;
  }
}

.p-consult__recommend {
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 599px) {
  .p-consult__recommend {
    margin-top: 2.25rem;
  }
}

.p-consult__lists-ttl {
  color: #002366;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .p-consult__lists-ttl {
    font-size: 1.3rem;
    text-align: left;
  }
}

.p-consult__lists-ttl::before,
.p-consult__lists-ttl::after {
  content: "～";
  font-size: inherit;
}

.p-consult__lists {
  text-align: left;
  margin-top: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  width: fit-content;
}
@media screen and (max-width: 599px) {
  .p-consult__lists {
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.7;
    margin-left: initial;
  }
}

.p-consult__lists li {
  text-align: left;
  width: fit-content;
}

.p-consult__lists li::before {
  content: "・";
  color: inherit;
}

.p-consult__form {
  margin-top: 2.5rem;
}

.p-consult__form {
  width: 100%;
}
@media screen and (max-width: 782px) {
  .p-consult__form {
    height: initial;
  }
}

.p-consult__form iframe {
  width: 100%;
}

.p-develop__subttl {
  font-size: 1rem;
  margin-top: 0.75rem;
  color: #002366;
}
@media screen and (max-width: 599px) {
  .p-develop__subttl {
    font-size: 1.1rem;
    text-align: left;
  }
}

.p-develop__main {
  margin-top: 2.65rem;
}

.p-dev-swiper__bg {
  overflow: hidden;
  position: relative;
  padding-bottom: 6.6rem;
}

.p-dev-swiper__bg::before {
  position: absolute;
  top: -0.95rem;
  left: calc(50% + 1.45rem);
  content: "";
  display: block;
  background-image: url("/img/2024/develop-phone.png");
  background-size: 100%;
  width: 56.55rem;
  height: 43.6445rem;
}
@media screen and (max-width: 599px) {
  .p-dev-swiper__bg::before {
    transform: translate(-50%, 0);
    top: initial;
    bottom: 0rem;
    left: calc(50% + 4.35rem);
  }
}

.p-dev-swiper__bgcolor {
  background-color: #D7E4EC;
}

.p-dev-swiper__inner {
  max-width: 65.75rem;
  margin: 0 auto;
  padding: 4.65rem 1.25rem 4.35rem;
}
@media screen and (max-width: 599px) {
  .p-dev-swiper__inner {
    padding-top: 2.25rem;
  }
}

.p-dev-swiper__main {
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media screen and (max-width: 599px) {
  .p-dev-swiper__main {
    flex-direction: column;
    align-items: center;
    gap: 4rem;
  }
}

.p-dev-swiper__admin {
  position: relative;
  width: 44.3rem;
  aspect-ratio: 886/540;
  object-fit: cover;
}
@media screen and (max-width: 599px) {
  .p-dev-swiper__admin {
    width: 100%;
  }
}

.p-dev-swiper__admin::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -3.5rem;
  transform: translateY(-50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  width: 1.15rem;
  height: 2.6rem;
  background-color: #002366;
}
@media screen and (max-width: 599px) {
  .p-dev-swiper__admin::after {
    width: 2.6rem;
    height: 1.15rem;
    clip-path: polygon(0 0, 50% 100%, 100% 0%);
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 1rem);
  }
}

.p-dev-swiper__sp {
  border-radius: 1.25rem;
  overflow: hidden;
  aspect-ratio: 250/540;
  width: 12.5rem;
  object-fit: cover;
  background-color: #000000;
}
@media screen and (max-width: 599px) {
  .p-dev-swiper__sp {
    width: 12.5rem;
  }
}

.p-dev-swiper .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: -2.75rem;
}
@media screen and (max-width: 599px) {
  .p-dev-swiper .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: -4rem;
  }
}

@media screen and (max-width: 599px) {
  .p-dev-swiper .swiper-pagination-bullet {
    background-color: #ffffff;
    opacity: 0.9;
  }
}

.p-dev-swiper .swiper-pagination-bullet-active {
  background-color: #002366;
}

@media screen and (max-width: 599px) {
  .p-doc-download {
    overflow: hidden;
  }
}

.p-doc-download__inner.l-inner {
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  max-width: 50rem;
}
@media screen and (max-width: 599px) {
  .p-doc-download__inner.l-inner {
    max-width: 100%;
    padding-bottom: 0.75rem;
  }
}

.p-doc-download.p-doc-download--complete .p-doc-download__inner.l-inner {
  max-width: 60rem;
}
@media screen and (max-width: 782px) {
  .p-doc-download.p-doc-download--complete .p-doc-download__inner.l-inner {
    max-width: 100%;
    padding-bottom: 0.75rem;
  }
}

.p-doc-download__ttl {
  color: #0459e2;
  font-size: 2.1rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 782px) {
  .p-doc-download__ttl {
    text-align: left;
    line-height: 1.5;
  }
}

.p-doc-download__text {
  margin-top: 1rem;
  line-height: 1.5;
  font-size: 0.8rem;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .p-doc-download__text {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 1.2rem;
    line-height: 1.7;
  }
}

.p-doc-download__lists-ttl {
  color: #002366;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .p-doc-download__lists-ttl {
    font-size: 1.3rem;
    text-align: left;
  }
}

.p-doc-download__lists-ttl::before,
.p-doc-download__lists-ttl::after {
  content: "～";
  font-size: inherit;
}

.p-doc-download__lists {
  text-align: left;
  margin-top: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  width: fit-content;
}
@media screen and (max-width: 599px) {
  .p-doc-download__lists {
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.7;
    margin-left: initial;
  }
}

.p-doc-download__lists li {
  text-align: left;
  width: fit-content;
}

.p-doc-download__lists li::before {
  content: "・";
  color: inherit;
}

.p-doc-download__form {
  width: 100%;
}

.p-doc-download__main {
  margin-top: 2.5rem;
  display: flex;
  gap: 3rem;
}
@media screen and (max-width: 599px) {
  .p-doc-download__main {
    flex-direction: column;
    gap: 2rem;
  }
}

.p-doc-download__img img {
  max-width: 20rem;
  box-shadow: 0.2rem 0.2rem 0.5rem #d0dffc;
}
@media screen and (max-width: 599px) {
  .p-doc-download__img img {
    max-width: 100%;
  }
}

.p-doc-download__policy {
  margin-top: 2.5rem;
  line-height: 1.5;
  font-size: 0.7rem;
  text-align: left;
}
@media screen and (max-width: 599px) {
  .p-doc-download__policy {
    text-align: left;
    font-size: 1rem;
    line-height: 1.7;
  }
}

.p-doc-download__policy-text {
  line-height: 1.7;
}

.p-doc-download__policy-text a {
  text-decoration: underline;
  transition: opacity 0.3s;
}

.p-doc-download__policy-text a:hover {
  opacity: 0.4;
}

.p-doc-download__policy-ttl {
  margin-top: 0.5rem;
}

.p-doc-download__policy-lists li {
  position: relative;
  padding-left: 1rem;
  line-height: 1.7;
}

.p-doc-download__policy-lists li::before {
  position: absolute;
  content: "・";
  left: 0;
  top: 0.05rem;
}

.p-download-form {
  margin-top: initial;
  font-size: 0.65rem;
}
@media screen and (max-width: 599px) {
  .p-download-form {
    text-align: left;
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.7;
  }
}

.p-download-form__req {
  width: fit-content;
  padding: 0.075rem 0.2rem;
  line-height: 1;
  font-size: 0.5rem;
  background-color: rgb(246, 73, 73);
  color: #ffffff;
}

.p-download-form__label {
  margin-left: 0.3rem;
  vertical-align: middle;
}

.p-download-form__label > span {
  font-size: 0.5rem;
  display: block;
  margin-top: -0.3rem;
}

.p-download-form__label > span.small {
  margin-top: 0.075rem;
  font-size: 0.5rem;
}

.p-download-form th {
  width: 50%;
}
@media screen and (max-width: 599px) {
  .p-download-form th {
    width: 72%;
  }
}

.p-download-form tr {
  vertical-align: middle;
  display: block;
  border-top: 1px solid #D6D6D6;
  display: flex;
  align-items: center;
}

.p-download-form tr:last-child {
  border-bottom: 1px solid #D6D6D6;
}

.p-download-form td,
.p-download-form th {
  vertical-align: middle;
}

.p-download-form td {
  width: 100%;
  background-color: transparent !important;
}

.p-download-form td input {
  background-color: #FCFCFC;
  border: 1px solid #E1E1E1;
  border-radius: 0.15rem;
  width: 100%;
  font-size: 0.65rem;
  box-sizing: border-box;
  padding: 0.45rem;
}
@media screen and (max-width: 599px) {
  .p-download-form td input {
    font-size: 1rem;
  }
}

.p-download-form td,
.p-download-form th {
  padding: 0.7rem 0;
}

.p-doc-complete {
  margin-top: 2.5rem;
  display: flex;
  gap: 3rem;
}
@media screen and (max-width: 782px) {
  .p-doc-complete {
    gap: 2.25rem;
    flex-direction: column;
  }
}

.p-doc-complete__right {
  width: 100%;
}

.p-doc-download__form button {
  margin-top: 1rem;
  background-color: #003398;
  color: #ffffff;
  font-weight: 700;
  width: 8rem;
  text-align: center;
  padding: 0.45rem 0.15rem;
  font-size: 0.8rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: opacity 0.2s;
}

.p-doc-download__form button:hover {
  opacity: 0.3;
}

.p-doc-complete__left {
  width: 18%;
  width: 15rem;
}
@media screen and (max-width: 782px) {
  .p-doc-complete__left {
    width: 100%;
    text-align: left;
  }
}

@media screen and (max-width: 782px) {
  .p-doc-complete__text {
    font-size: 1.1rem;
  }
}

.p-doc-complete__left-text {
  text-align: left;
  font-size: 0.9rem;
}
@media screen and (max-width: 782px) {
  .p-doc-complete__left-text {
    font-size: 1.1rem;
    text-align: left;
  }
}

.p-doc-complete__left-text a.tel {
  pointer-events: none;
}
@media screen and (max-width: 782px) {
  .p-doc-complete__left-text a.tel {
    pointer-events: initial;
    text-decoration: underline;
    color: #0459e2;
  }
}

.p-doc-complete__right {
  text-align: left;
}

.p-doc-complete__lists-ttl {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1.2rem;
}

.p-doc-complete__lists {
  margin-top: 0.15rem;
  font-size: 1.1rem;
}

.p-doc-complete__lists li::before {
  content: "・";
}

.p-doc-complete__form {
  margin-top: 2rem;
}

.p-doc-complete__form iframe {
  width: 100%;
}

.p-dx__inner.l-inner {
  max-width: 54.4rem;
}

.p-dx__item:not(:first-child) {
  margin-top: 7.1rem;
}

.l-faq-anchor {
  margin-top: 5rem;
}
@media screen and (max-width: 599px) {
  .l-faq-anchor {
    margin-top: 2.5rem;
  }
}

.p-faq-anchor__items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1.5rem;
  column-gap: 6rem;
}
@media screen and (max-width: 599px) {
  .p-faq-anchor__items {
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
  }
}

.p-faq-anchor__link {
  display: block;
  position: relative;
  color: #003398;
  font-weight: 700;
  line-height: 1.4444444444;
  padding-bottom: 1.75rem;
  font-size: 0.9rem;
}
@media screen and (max-width: 599px) {
  .p-faq-anchor__link {
    font-size: 1rem;
  }
}

.p-faq-anchor__link::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0.5rem;
  transform: translateX(-50%);
  aspect-ratio: 1/1;
  text-align: center;
  display: block;
  width: 0.9rem;
  background-image: url("/img/2024/arrow-bottom.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 599px) {
  .p-faq-anchor__link::before {
    width: 1.2rem;
    bottom: 0.25rem;
  }
}

.p-faq-anchor__link::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #003398;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s;
}
@media screen and (max-width: 599px) {
  .p-faq-anchor__link::after {
    height: 2px;
  }
}

.p-faq-anchor__link:hover::after {
  opacity: 1;
}

.l-faq-main {
  margin-top: 7rem;
}
@media screen and (max-width: 599px) {
  .l-faq-main {
    margin-top: 4rem;
  }
}

.p-faq-main__inner {
  padding-bottom: 5rem;
}
@media screen and (max-width: 599px) {
  .p-faq-main__inner {
    padding-bottom: 3.75rem;
  }
}

.p-faq-main__group {
  padding-top: 1.5rem;
}
@media screen and (max-width: 599px) {
  .p-faq-main__group {
    padding-top: 1rem;
  }
}

.p-faq-main__group:not(:first-child) {
  margin-top: 6.5rem;
}
@media screen and (max-width: 599px) {
  .p-faq-main__group:not(:first-child) {
    margin-top: 4rem;
  }
}

.p-faq-group__ttl {
  color: #003398;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.4285714286;
}
@media screen and (max-width: 599px) {
  .p-faq-group__ttl {
    font-size: 1.2rem;
  }
}

.p-faq-group__items {
  margin-top: 1.5rem;
}

.p-faq__inner.l-inner {
  text-align: center;
  max-width: 54.35rem;
}

.p-faq__ttl {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3.75rem;
}
@media screen and (max-width: 599px) {
  .p-faq__ttl {
    gap: initial;
  }
}

.p-faq__right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-faq__text {
  margin-top: 2.35rem;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  color: #002366;
}
@media screen and (max-width: 599px) {
  .p-faq__text {
    margin-top: 1.5rem;
  }
}

.p-faq__img {
  margin-left: -1.2rem;
  max-width: 14.9rem;
  width: 100%;
}
@media screen and (max-width: 599px) {
  .p-faq__img {
    max-width: 70%;
  }
}

.p-faq__group {
  margin-top: 3.1rem;
}
@media screen and (max-width: 599px) {
  .p-faq__group {
    margin-top: 2rem;
  }
}

.p-faq__btn {
  margin-top: 3.1rem;
}
@media screen and (max-width: 599px) {
  .p-faq__btn {
    margin-top: 2.5rem;
  }
}

.p-flow-cus__ttl {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #002366;
  line-height: 1.4375;
}

.p-flow-cus__items {
  margin-top: 1.95rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1.6rem;
  column-gap: 2.4rem;
  max-width: 50.2rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 599px) {
  .p-flow-cus__items {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
}

.p-flow-cuss__item {
  /*グリットアイテムを3グリット分の大きさにする*/
  grid-row: span 4;
  /*サブグリットの指定*/
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  width: 100%;
}

.c-flow-cus {
  padding: 4.1rem 0 2.1rem;
  background-color: #ffffff;
  text-align: center;
  border-radius: 0.9rem;
  color: #002366;
  border: 1px solid #B5B5B5;
  text-align: center;
}

.c-flow-cus > * {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
@media screen and (max-width: 782px) {
  .c-flow-cus > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media screen and (max-width: 599px) {
  .c-flow-cus > * {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

.c-flow-cus__top {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}
@media screen and (max-width: 782px) {
  .c-flow-cus__top {
    gap: 1rem;
  }
}
@media screen and (max-width: 599px) {
  .c-flow-cus__top {
    gap: 2.25rem;
  }
}

.c-flow-cus__ttl {
  margin-top: 1rem;
  position: relative;
  text-align: left;
  height: min-content;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 782px) {
  .c-flow-cus__ttl {
    font-size: 1.35rem;
  }
}

.c-flow-cus__ttl::after {
  content: "";
  display: block;
  bottom: -0.5rem;
  left: 0;
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #002366;
}

.c-flow-cus__img {
  height: 6rem;
  width: 6rem;
}

.c-flow-cus__subttl {
  margin-top: -1.75rem;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.4285714286;
}
@media screen and (max-width: 782px) {
  .c-flow-cus__subttl {
    font-size: 1.6rem;
  }
}

.c-flow-cus__text {
  margin-top: 1.1rem;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
}
@media screen and (max-width: 782px) {
  .c-flow-cus__text {
    font-size: 1.2rem;
  }
}

.c-flow-cus__btn {
  margin-top: 1.65rem;
}

.l-flow {
  margin-top: 0.55rem;
}

.p-flow__inner.l-inner {
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding-top: 5.1rem;
  padding-bottom: 6.05rem;
  max-width: 68.9rem;
}
@media screen and (max-width: 599px) {
  .p-flow__inner.l-inner {
    padding-top: 2.5rem;
    padding-bottom: 2.25rem;
  }
}

.p-flow__body {
  padding: 0 6.5rem;
}
@media screen and (max-width: 599px) {
  .p-flow__body {
    padding: 0 1.5rem;
  }
}

.p-flow__section:not(:first-child) {
  margin-top: 3.1rem;
}
@media screen and (max-width: 599px) {
  .p-flow__section:not(:first-child) {
    margin-top: 5rem;
  }
}

.p-flow-section__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d7e4ec;
  padding-bottom: 0.5rem;
}
@media screen and (max-width: 599px) {
  .p-flow-section__head {
    flex-direction: column;
    padding-bottom: 1rem;
    align-items: flex-start;
    gap: 0.85rem;
  }
}

.p-flow-section__content {
  color: #002366;
  display: flex;
  align-items: center;
  gap: 0.9rem;
}
.p-flow-section__ttl-num {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 599px) {
  .p-flow-section__ttl-num {
    font-size: 2.5rem;
  }
}

.p-flow-section__ttl {
  display: block;
  margin-top: 0.65rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
}
@media screen and (max-width: 782px) {
  .p-flow-section__ttl {
    font-size: 1.8rem;
    margin-top: initial;
  }
}

.p-flow-section__cats {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  margin-top: 1.15rem;
}
@media screen and (max-width: 599px) {
  .p-flow-section__cats {
    margin-top: initial;
    gap: 0.4rem;
  }
}

.p-flow-section__cat {
  border: 1px solid #002366;
  color: #002366;
  display: block;
  font-size: 0.7rem;
  line-height: 1;
  min-width: 5.1rem;
  text-align: center;
  padding: 0.2rem;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .p-flow-section__cat {
    font-size: 0.9rem;
    padding: 0.4rem;
    min-width: 6.5rem;
  }
}

.p-flow-section__cat.current {
  color: #ffffff;
  background-color: #002366;
}

.p-flow-section__body {
  margin-top: 1.9rem;
  display: grid;
  gap: 3.9rem;
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 599px) {
  .p-flow-section__body {
    grid-template-columns: 1fr;
    margin-top: 1rem;
    gap: 5rem;
  }
}

.p-flow-section__card {
  position: relative;
}

.p-flow-section__card:not(:first-child)::before {
  position: absolute;
  content: "";
  display: block;
  width: 1.15rem;
  aspect-ratio: 23/52;
  background-color: #d7e4ec;
  top: 50%;
  left: -1.25rem;
  transform: translate(-100%, -50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
}
@media screen and (max-width: 599px) {
  .p-flow-section__card:not(:first-child)::before {
    aspect-ratio: 52/23;
    width: 3.25rem;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    top: -1.5rem;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

.p-flow-card {
  padding: 1.2rem 1.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 0;
  background-color: #ffffff;
  filter: drop-shadow(0.2rem 0.2rem 0.5rem #d0dffc);
}
@media screen and (max-width: 599px) {
  .p-flow-card {
    padding: 1.75rem 2.25rem 2.75rem;
  }
}

.p-flow-card__img {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6.5rem;
  gap: 1rem;
}
@media screen and (max-width: 599px) {
  .p-flow-card__img {
    min-height: 8rem;
  }
}

.p-flow-card__img.p-flow-card__img--logos img {
  width: 4.5rem;
}

.p-flow-card__img img {
  aspect-ratio: 1/1;
  height: auto;
  width: 6.5rem;
}
@media screen and (max-width: 599px) {
  .p-flow-card__img img {
    width: 9rem;
  }
}

.p-flow-card__ttl {
  margin-top: 0.5rem;
  color: #002366;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.45;
  min-height: 2.9rem;
}
@media screen and (max-width: 599px) {
  .p-flow-card__ttl {
    margin-top: 0.75rem;
    min-height: initial;
    font-size: 1.2rem;
  }
}

.p-flow-card__text {
  margin-top: 0.5rem;
  text-align: left;
  color: #002366;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
}
@media screen and (max-width: 599px) {
  .p-flow-card__text {
    margin-top: 0.75rem;
    font-size: 1rem;
  }
}

.l-kinou-anchor {
  margin-top: 4.05rem;
}
@media screen and (max-width: 599px) {
  .l-kinou-anchor {
    margin-top: 3rem;
  }
}

.p-kinou-anchor__items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.885rem;
}
@media screen and (max-width: 599px) {
  .p-kinou-anchor__items {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

.p-kinou-anchor__item {
  position: relative;
  text-align: center;
  color: #003398;
  font-weight: 700;
  line-height: 1.4444444444;
  font-size: 0.9rem;
  padding: 0.15rem 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.3s;
}
@media screen and (max-width: 599px) {
  .p-kinou-anchor__item {
    font-size: 1.1rem;
  }
}

.p-kinou-anchor__item:hover {
  opacity: 0.6;
}

.p-kinou-anchor__arrow {
  margin-top: 0.15rem;
  aspect-ratio: 1/1;
  text-align: center;
  display: block;
  width: 0.9rem;
  background-image: url("/img/2024/arrow-bottom.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 599px) {
  .p-kinou-anchor__arrow {
    width: 1.1rem;
  }
}

.p-kinou {
  overflow: hidden;
}

.p-kinou__inner {
  padding-top: 5rem;
  padding-bottom: 5rem;
  max-width: 60.15rem;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .p-kinou__inner {
    padding: 0 1.25rem;
  }
}

.p-kinou-item {
  position: relative;
  display: flex;
  justify-content: left;
  color: #002366;
}
@media screen and (max-width: 782px) {
  .p-kinou-item {
    flex-direction: column;
    align-items: center;
  }
}

.p-kinou-item__inner {
  width: 24rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
@media screen and (max-width: 782px) {
  .p-kinou-item__inner {
    text-align: center;
    align-items: center;
    width: 100%;
  }
}

.p-kinou-item__img {
  position: absolute;
  left: calc(50% - 7.3rem);
  top: -4.75rem;
  width: 55.3325rem;
}
@media screen and (max-width: 782px) {
  .p-kinou-item__img {
    margin-top: 1.5rem;
    position: initial;
  }
}

.p-kinou-item__ttl {
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  width: 10.8rem;
  display: inline-block;
  padding: 0.435rem 1rem;
  text-align: center;
  background-color: #ffffff;
  border-radius: 1.5rem;
  border: 1px solid #002366;
}
@media screen and (max-width: 599px) {
  .p-kinou-item__ttl {
    font-size: 1.3rem;
    width: 12rem;
    padding: 0.6rem 1rem;
  }
}

.p-kinou-item__subttl {
  margin-top: 1.4rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.45;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 599px) {
  .p-kinou-item__subttl {
    text-align: left;
    width: 100%;
  }
}

.p-kinou-item__text {
  margin-top: 1.1rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
}
@media screen and (max-width: 599px) {
  .p-kinou-item__text {
    font-size: 1.2rem;
    text-align: left;
  }
}

.p-kinou__btn {
  margin-top: 10rem;
}
@media screen and (max-width: 599px) {
  .p-kinou__btn {
    margin-top: 2.5rem;
  }
}

.p-kinou-item:not(:first-child) {
  margin-top: 14rem;
}
@media screen and (max-width: 599px) {
  .p-kinou-item:not(:first-child) {
    margin-top: 5rem;
  }
}

.p-kinou-item:nth-child(even) {
  justify-content: right;
}

.p-kinou-item:nth-child(even) .p-kinou-item__img {
  right: calc(50% - 7.3rem);
  left: initial;
}

.p-mv-sub {
  background-color: #ffffff;
}

.p-mv-sub__inner {
  display: flex;
  justify-content: space-between;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__inner {
    flex-direction: column;
    padding: 1.25rem 0.75rem 0;
  }
}

.p-mv-sub__main {
  margin-top: 1.5rem;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__main {
    margin-top: initial;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
}

.p-mv-sub__subttl {
  color: #003398;
  font-size: 1rem;
  letter-spacing: 0.1em;
  line-height: 1.45;
  font-weight: 700;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__subttl {
    font-size: 1.4rem;
    text-align: left;
  }
}

.p-mv-sub__ttl {
  display: block;
  color: #003398;
  width: 100%;
  font-size: 2.1rem;
  line-height: 1.5714285714;
  letter-spacing: 0.05em;
  font-weight: 900;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__ttl {
    margin-top: 1rem;
    font-size: 2.9rem;
    margin-left: initial;
    text-align: left;
    line-height: 1.2;
  }
}

.p-mv-sub__ttl > .blue {
  color: #0057ff;
}

.p-mv-sub__lists {
  margin-left: 0.7rem;
  margin-top: 0.3rem;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__lists {
    margin-top: 0.5rem;
  }
}

.p-mv-sub__list {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  line-height: 1.5555555556;
  font-weight: 700;
  text-align: left;
}
@media screen and (max-width: 782px) {
  .p-mv-sub__list {
    font-size: 1.2rem;
  }
}

.p-mv-sub__list::before {
  content: ""; /* 空の要素作成 */
  position: absolute;
  width: 0.75rem; /* 幅指定 */
  height: 0.75rem; /* 高さ指定 */
  display: inline-block; /* インラインブロックにする */
  background-color: #0057ff; /* 背景色指定 */
  position: relative; /* 位置調整 */
  top: 0.45rem; /* 位置調整 */
  transform: translateY(-50%);
  margin-right: 0.5rem; /* 余白指定 */
}

.p-mv-sub__btns {
  margin-top: 0.55rem;
  display: flex;
  gap: 0.585rem;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__btns {
    margin-top: 1.6rem;
    gap: 1rem;
    order: 4;
    align-items: center;
    padding: 0 0.5rem;
  }
}

.p-mv-sub__results {
  margin-top: 0.8rem;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__results {
    margin-left: initial;
    justify-content: center;
  }
}

.p-mv-sub__result-img {
  width: 13.595rem;
}

.p-mv-sub__note {
  margin-top: 0.2rem;
  margin-left: 0.6rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.4285714286;
}
.p-mv-sub__img {
  aspect-ratio: 400/288;
  width: 20rem;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__img {
    margin-top: 2.5rem;
    width: 100vw;
  }
}

.p-mv-sub__img img {
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 599px) {
  .p-mv-sub__img img {
    height: inherit;
    object-fit: initial;
  }
}

@media screen and (max-width: 599px) {
  .p-mv-sub.p-mv-sub--faq .p-mv-sub__img {
    aspect-ratio: initial;
  }
}

.p-mv-sub.p-mv-sub--news .p-mv-sub__main,
.p-mv-sub.p-mv-sub--faq .p-mv-sub__main {
  margin-top: initial;
}

.p-mv {
  overflow: hidden;
}

.p-mv__inner {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: 66.7rem;
  margin-left: auto;
  text-align: center;
}
@media screen and (max-width: 782px) {
  .p-mv__inner {
    padding: 1.25rem 0.75rem 0;
  }
}

.p-mv__main {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
@media screen and (max-width: 599px) {
  .p-mv__main {
    flex-direction: column;
  }
}

.p-mv__subttl {
  color: #0057FF;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  line-height: 1.4583333333;
  font-weight: 900;
  margin-left: 0.65rem;
}
@media screen and (max-width: 599px) {
  .p-mv__subttl {
    order: 1;
    margin-left: initial;
    font-size: 1.5rem;
    margin-left: 1rem;
    line-height: 1.3;
  }
}

.p-mv__ttl {
  display: block;
  color: #0057FF;
  width: 100%;
  white-space: nowrap;
  font-size: 2.9rem;
  line-height: 1.32;
  letter-spacing: 0.05em;
  font-weight: 900;
  margin-left: 0.25rem;
}
@media screen and (max-width: 599px) {
  .p-mv__ttl {
    order: 2;
    margin-top: 0.5rem;
    font-size: 3.67rem;
    margin-left: 0.5rem;
    line-height: 1.13;
  }
}

.p-mv__ttl span.p-mv__ttl--deepblue {
  color: #002366;
}

.p-mv__ttl span.p-mv__ttl--small {
  font-size: 2rem;
}
@media screen and (max-width: 599px) {
  .p-mv__ttl span.p-mv__ttl--small {
    font-size: 2.7rem;
  }
}

.p-mv__lists {
  margin-left: 0.7rem;
  margin-top: 0.3rem;
}
@media screen and (max-width: 599px) {
  .p-mv__lists {
    order: 3;
    margin-top: 1rem;
  }
}

.p-mv__list {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  line-height: 1.5555555556;
  font-weight: 700;
  text-align: left;
  color: #002366;
  padding-left: 1.2rem;
}
@media screen and (max-width: 599px) {
  .p-mv__list {
    padding: 0 0 0 1.25rem;
    font-size: 1.17rem;
    font-weight: 500;
    letter-spacing: 0.05em;
  }
}

@media screen and (max-width: 599px) {
  .p-mv__list:not(:first-child) {
    margin-top: -0.25rem;
  }
}

.p-mv__list::before {
  content: ""; /* 空の要素作成 */
  position: absolute;
  width: 0.75rem; /* 幅指定 */
  height: 0.75rem; /* 高さ指定 */
  display: inline-block; /* インラインブロックにする */
  background-color: #003398; /* 背景色指定 */
  top: 0.75rem; /* 位置調整 */
  left: 0;
  transform: translateY(-50%);
  margin-right: 0.5rem; /* 余白指定 */
}
@media screen and (max-width: 599px) {
  .p-mv__list::before {
    width: 0.9rem; /* 幅指定 */
    height: 0.9rem; /* 高さ指定 */
    top: 0.9rem; /* 位置調整 */
  }
}

.p-mv__btns {
  margin-top: 0.95rem;
  display: flex;
  gap: 1.5rem;
}
@media screen and (max-width: 599px) {
  .p-mv__btns {
    order: 4;
    margin-top: 1.6rem;
    gap: 1rem;
    align-items: center;
    padding: 0 0.5rem;
  }
}

.p-mv__results {
  margin-top: 0.8rem;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
@media screen and (max-width: 599px) {
  .p-mv__results {
    margin-top: -0.4rem;
    order: 6;
    margin-left: initial;
    justify-content: center;
  }
}

.p-mv__result-img {
  width: 13.595rem;
}

.p-mv__note {
  margin-top: 0.2rem;
  margin-left: 0.6rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.4285714286;
  color: #002366;
}
@media screen and (max-width: 599px) {
  .p-mv__note {
    margin-top: 0.1rem;
    margin-left: 1.5rem;
    order: 7;
    font-size: 0.667rem;
  }
}

.p-mv__right {
  position: relative;
  width: 100%;
}

.p-mv__img {
  pointer-events: none;
  width: 32.522rem;
  position: absolute;
  top: 1.1rem;
  left: calc(50% - 1.75rem);
}
@media screen and (max-width: 599px) {
  .p-mv__img {
    left: initial;
    top: initial;
    margin-top: 0.5rem;
    margin-top: initial;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    position: initial;
    order: 5;
  }
}

.p-news__items {
  margin-top: 1.45rem;
}

.p-news__ttl {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #002366;
}
@media screen and (max-width: 599px) {
  .p-news__ttl {
    font-size: 1.8rem;
  }
}

.p-news__inner.l-inner {
  max-width: 55.1rem;
}

.p-news__btn {
  margin-top: 1.5rem;
}

.p-news__pagination {
  margin-top: 1.5rem;
}
@media screen and (max-width: 599px) {
  .p-news__pagination {
    margin-top: 1rem;
  }
}

.p-posts {
  padding-top: 3rem;
  padding-bottom: 2.5rem;
  background-color: #d7e4ec;
}

.p-posts__items {
  margin-top: 0.95rem;
}
@media screen and (max-width: 599px) {
  .p-posts__items {
    margin-top: 1.5rem;
  }
}

.p-posts__btn {
  margin-top: 0.65rem;
}

.l-press-anchor {
  margin-top: 5rem;
}
@media screen and (max-width: 599px) {
  .l-press-anchor {
    margin-top: 2.5rem;
  }
}

.p-press-anchor__inner.l-inner {
  max-width: 45rem;
}

.p-press-anchor__items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
}
@media screen and (max-width: 599px) {
  .p-press-anchor__items {
    grid-template-columns: 1fr 1fr;
  }
}

.p-press-anchor__link {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.5rem 0.25rem;
  background-color: #cee2ff;
  border-radius: 1.25rem;
  height: 100%;
  transition: opacity 0.3s;
}

.p-press-anchor__link:hover {
  opacity: 0.6;
}

.p-press-anchor__item.current .p-press-anchor__link {
  pointer-events: none;
  background-color: #0459e2;
  color: #ffffff;
}

.p-price__items {
  margin-top: 1.85rem;
}

.p-price__btn {
  margin-top: 3.5rem;
}
@media screen and (max-width: 599px) {
  .p-price__btn {
    margin-top: 2.5rem;
  }
}

.p-product-data {
  color: #002366;
}

.p-product-data__top {
  display: flex;
  justify-content: center;
  gap: 3.75rem;
  text-align: left;
}
@media screen and (max-width: 599px) {
  .p-product-data__top {
    flex-direction: column;
    gap: 2.5rem;
  }
}

.p-product-data__top-item {
  width: 100%;
}

.p-product-data__top-ttl {
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.8181818182;
}
@media screen and (max-width: 599px) {
  .p-product-data__top-ttl {
    margin-top: 0.5rem;
    font-size: 1.4rem;
  }
}

.p-product-data__top-text {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
}
@media screen and (max-width: 599px) {
  .p-product-data__top-text {
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }
}

.p-product-data__bottom {
  margin-top: 2.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
}

.p-product-data__bottom-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  row-gap: 1.1rem;
}
@media screen and (max-width: 599px) {
  .p-product-data__bottom-item {
    flex-direction: column;
  }
}

.p-product-data__bottom-img {
  aspect-ratio: 690/270;
  width: 66.6023166%;
}
@media screen and (max-width: 599px) {
  .p-product-data__bottom-img {
    width: 100%;
  }
}

.p-product-data__bottom-cats {
  width: 33.3976834%;
  padding: 0.6rem;
}
@media screen and (max-width: 599px) {
  .p-product-data__bottom-cats {
    width: 100%;
  }
}

.p-product-data__bottom-ttl {
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.4545454545;
  letter-spacing: 0.05em;
  text-align: left;
  align-self: flex-start;
}
@media screen and (max-width: 599px) {
  .p-product-data__bottom-ttl {
    font-size: 1.4rem;
  }
}

.p-product-data__bottom-text {
  max-width: 24rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
}
@media screen and (max-width: 599px) {
  .p-product-data__bottom-text {
    font-size: 1.2rem;
  }
}

.p-product__ttl {
  color: #002366;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 2.0625;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #cdd6dd;
  padding: 0 3rem;
}
@media screen and (max-width: 782px) {
  .p-product__ttl {
    padding: initial;
    text-align: left;
  }
}

.p-product.p-product--bumon .p-product__ttl {
  position: relative;
  line-height: 1.25;
  width: 100%;
  padding-bottom: 2.85rem;
}
@media screen and (max-width: 599px) {
  .p-product.p-product--bumon .p-product__ttl {
    padding-bottom: 1rem;
  }
}

.p-product.p-product--bumon .p-product__ttl-img {
  position: absolute;
  left: 0;
  top: calc(50% - 1.3rem);
  transform: translateY(-50%);
  width: 6.8rem;
  height: 6.8rem;
}
@media screen and (max-width: 599px) {
  .p-product.p-product--bumon .p-product__ttl-img {
    display: none;
  }
}

.p-product__inner {
  padding-top: 4.65rem;
  background-color: #ffffff;
  padding-bottom: 4.5rem;
}
@media screen and (max-width: 599px) {
  .p-product__inner {
    padding-top: 3rem;
    box-shadow: 0.2rem 0.2rem 0.5rem #d0dffc;
  }
}

.p-product__inner.l-inner {
  max-width: 66.4rem;
  padding-left: 7.3rem;
  padding-right: 7.3rem;
}
@media screen and (max-width: 599px) {
  .p-product__inner.l-inner {
    max-width: calc(100% - 2.5rem);
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.p-product__items,
.p-product__main {
  margin-top: 3.9rem;
}
@media screen and (max-width: 599px) {
  .p-product__items,
.p-product__main {
    margin-top: 3rem;
  }
}

.p-product.p-product--bumon .p-product__items {
  margin-top: 4.3rem;
}
@media screen and (max-width: 599px) {
  .p-product.p-product--bumon .p-product__items {
    margin-top: 2rem;
  }
}

.p-product.p-product--bumon .p-product__item:not(:first-child) {
  margin-top: 2.8rem;
}
@media screen and (max-width: 599px) {
  .p-product.p-product--bumon .p-product__item:not(:first-child) {
    margin-top: 3.5rem;
  }
}

.c-product-ttl {
  color: #002366;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 599px) {
  .c-product-ttl {
    align-items: center;
    flex-direction: column;
  }
}

.c-product-ttl__info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 24rem;
  text-align: left;
}
@media screen and (max-width: 599px) {
  .c-product-ttl__info {
    max-width: 100%;
  }
}

.c-product-ttl__ttl {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 2.0625;
  border-bottom: 1px solid #cdd6dd;
}
@media screen and (max-width: 599px) {
  .c-product-ttl__ttl {
    font-size: 1.7rem;
  }
}

.c-product-ttl.c-product-ttl--kinou .c-product-ttl__ttl {
  line-height: 1.375;
  border-bottom: initial;
}

.c-product-ttl__subttl {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.5;
}
@media screen and (max-width: 599px) {
  .c-product-ttl__subttl {
    font-size: 1.4rem;
  }
}

.c-product-ttl__text {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
}
@media screen and (max-width: 599px) {
  .c-product-ttl__text {
    font-size: 1.2rem;
  }
}

.c-product-ttl__img {
  aspect-ratio: 245/245;
  width: 12.25rem;
}
@media screen and (max-width: 599px) {
  .c-product-ttl__img {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;
    display: none;
  }
}

.p-solution-move__inner.l-inner {
  max-width: 57.5rem;
}

.p-solution-move__items {
  display: flex;
  gap: 0.2rem;
  justify-content: space-between;
  justify-content: center;
}
@media screen and (max-width: 599px) {
  .p-solution-move__items {
    gap: 0.9rem;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
}

.p-solution-move__link {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 700;
  color: #002366;
  border: 1px solid #002366;
  padding: 0.6855rem;
  transition: opacity 0.3s;
  max-width: 13.6rem;
  line-height: 1;
}
@media screen and (max-width: 599px) {
  .p-solution-move__link {
    max-width: 100%;
    line-height: 1.2;
    width: 100%;
    font-size: 1.4rem;
    text-align: left;
  }
}

.p-solution-move__link span {
  font-size: 0.5rem;
}
@media screen and (max-width: 599px) {
  .p-solution-move__link span {
    font-size: 0.9rem;
  }
}

.p-solution-move__link::before {
  content: "";
  position: absolute;
  right: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  background-image: url("/img/2024/solution-icon.svg");
  background-size: 100% 100%;
}

.p-solution-move__link:hover {
  opacity: 0.5;
}

.p-solution__items {
  display: grid;
  margin-top: 1.65rem;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.6rem;
  column-gap: 1.6rem;
  max-width: 55.1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 599px) {
  .p-solution__items {
    grid-template-columns: 1fr;
    max-width: 100%;
    row-gap: 2.5rem;
  }
}

.p-solution__item {
  /*グリットアイテムを3グリット分の大きさにする*/
  grid-row: span 6;
  /*サブグリットの指定*/
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  width: 100%;
}

.p-solution-item {
  background-color: #ffffff;
  padding: 0.95rem 0 1.9rem;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0.2rem 0.2rem 0.5rem #d0dffc;
  color: #002366;
}

.p-solution-item > * {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.p-solution-item__ttl {
  font-size: 4.8rem;
  letter-spacing: 0.05em;
  color: #0057FF;
  text-align: left;
  line-height: 1;
  font-weight: 400;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 599px) {
  .p-solution-item__ttl {
    font-size: 4.5rem;
  }
}

.p-solution-item__subttl {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.25;
  margin-top: 0.45rem;
}
@media screen and (max-width: 782px) {
  .p-solution-item__subttl {
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 599px) {
  .p-solution-item__subttl {
    font-size: 1.25rem;
    display: flex;
    align-items: flex-end;
  }
}

.p-solution-item__img {
  margin-top: -2.9rem;
  padding: 0 0.8rem 0 0.8rem;
}
@media screen and (max-width: 599px) {
  .p-solution-item__img {
    margin-top: -1.5rem;
  }
}

@media screen and (max-width: 599px) {
  .p-solution-item.p-solution-item--dx .p-solution-item__img {
    margin-top: -5.5rem;
  }
}

.p-solution-item.p-solution-item--cx .p-solution-item__img {
  padding: 0 0.3rem 0 2.45rem;
  transform: translateY(1.25rem);
}
@media screen and (max-width: 599px) {
  .p-solution-item.p-solution-item--cx .p-solution-item__img {
    margin-top: -2.25rem;
    transform: initial;
    padding: 0 0.3rem 0 4.5rem;
  }
}

.p-solution-item.p-solution-item--ex .p-solution-item__img {
  padding: 0 0.55rem 0 4.5rem;
  transform: translateY(1rem);
}
@media screen and (max-width: 599px) {
  .p-solution-item.p-solution-item--ex .p-solution-item__img {
    transform: initial;
    margin-top: -2.5rem;
    padding: 0 0.3rem 0 6.5rem;
  }
}

.p-solution-item__img img {
  width: 100%;
}

.p-solution-item__text {
  margin-top: 0.45rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
}
@media screen and (max-width: 782px) {
  .p-solution-item__text {
    font-size: 1.2rem;
  }
}

.p-solution-item__lists {
  margin-top: 0.95rem;
}

.p-solution-item__list {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  line-height: 1.5555555556;
  font-weight: 700;
  text-align: left;
}
@media screen and (max-width: 782px) {
  .p-solution-item__list {
    font-size: 1.2rem;
  }
}

.p-solution-item__list::before {
  content: ""; /* 空の要素作成 */
  position: absolute;
  width: 0.75rem; /* 幅指定 */
  height: 0.75rem; /* 高さ指定 */
  display: inline-block; /* インラインブロックにする */
  background-color: #003398; /* 背景色指定 */
  border-radius: 50%; /* 要素を丸くする */
  position: relative; /* 位置調整 */
  top: 0.45rem; /* 位置調整 */
  transform: translateY(-50%);
  margin-right: 0.5rem; /* 余白指定 */
}

.p-solution-item__btn {
  margin-top: 1.3rem;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
}
@media screen and (max-width: 599px) {
  .p-solution-item__btn {
    margin-top: 2.5rem;
  }
}

.l-banner {
  margin-top: 5.5rem;
}
@media screen and (max-width: 782px) {
  .l-banner {
    margin-top: 5rem;
  }
}

.l-breadcrumb {
  margin-top: 5.4rem;
}
@media screen and (max-width: 782px) {
  .l-breadcrumb {
    margin-top: 2rem;
  }
}

.l-breadcrumb-small {
  margin-top: 2.5rem;
}
@media screen and (max-width: 782px) {
  .l-breadcrumb-small {
    margin-top: 1rem;
  }
}

.l-bumon-anchor {
  margin-top: 1.45rem;
}
@media screen and (max-width: 782px) {
  .l-bumon-anchor {
    margin-top: 3rem;
  }
}

.l-bumon {
  margin-top: 4.5rem;
}
@media screen and (max-width: 782px) {
  .l-bumon {
    margin-top: 5rem;
  }
}

.l-contents {
  margin-top: 4.35rem;
}
@media screen and (max-width: 782px) {
  .l-contents {
    margin-top: 2rem;
  }
}

.l-develop {
  margin-top: 4.5rem;
}
@media screen and (max-width: 782px) {
  .l-develop {
    margin-top: 5rem;
  }
}

.l-dx {
  margin-top: 3.85rem;
}
@media screen and (max-width: 782px) {
  .l-dx {
    margin-top: 4rem;
  }
}

.l-faq {
  margin-top: 3.65rem;
}
@media screen and (max-width: 782px) {
  .l-faq {
    margin-top: 5rem;
  }
}

.l-flow-cus {
  margin-top: 0.95rem;
}
@media screen and (max-width: 782px) {
  .l-flow-cus {
    margin-top: 6rem;
  }
}

.l-inner {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
@media screen and (max-width: 782px) {
  .l-inner {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.l-kinou {
  margin-top: 2.4rem;
}
@media screen and (max-width: 782px) {
  .l-kinou {
    margin-top: 6rem;
  }
}

.l-news {
  margin-top: 4rem;
}
@media screen and (max-width: 782px) {
  .l-news {
    margin-top: 3rem;
  }
}

.l-news-archive {
  margin-top: 3rem;
}
@media screen and (max-width: 782px) {
  .l-news-archive {
    margin-top: 2rem;
  }
}

.l-page-catch {
  margin-top: 2.45rem;
}
@media screen and (max-width: 782px) {
  .l-page-catch {
    margin-top: 4rem;
  }
}

.l-price {
  margin-top: 5rem;
}
@media screen and (max-width: 782px) {
  .l-price {
    margin-top: 5rem;
  }
}

.l-product-next {
  margin-top: 2rem;
}
@media screen and (max-width: 782px) {
  .l-product-next {
    margin-top: 3rem;
  }
}

.l-product {
  margin-top: 3.5rem;
}
@media screen and (max-width: 782px) {
  .l-product {
    margin-top: 4.5rem;
  }
}

.l-product-repeat {
  margin-top: 3.5rem;
}
@media screen and (max-width: 782px) {
  .l-product-repeat {
    margin-top: 3rem;
  }
}

.l-product-bumon {
  margin-top: 7rem;
}
@media screen and (max-width: 782px) {
  .l-product-bumon {
    margin-top: 4.5rem;
  }
}

.l-product-kinou {
  margin-top: 7.5rem;
}
@media screen and (max-width: 782px) {
  .l-product-kinou {
    margin-top: 3rem;
  }
}

.l-result {
  margin-top: 2.35rem;
}
@media screen and (max-width: 782px) {
  .l-result {
    margin-top: 4.5rem;
  }
}

.l-solution-move {
  margin-top: 3.25rem;
}
@media screen and (max-width: 782px) {
  .l-solution-move {
    margin-top: 2rem;
  }
}

.l-solution {
  margin-top: 0.75rem;
}
@media screen and (max-width: 782px) {
  .l-solution {
    margin-top: 5rem;
  }
}

.l-tenpolab {
  padding-top: 1.7rem;
}
@media screen and (max-width: 782px) {
  .l-tenpolab {
    padding-top: 1.5rem;
  }
}

.u-main--bg {
  background-color: #F5F7F8;
}