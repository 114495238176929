@use "global" as *;
@use "sass:math";
.l-doc-download {
}

.p-doc-download {
    
    @include mq("sm"){
        overflow: hidden;
    }
}

.p-doc-download__inner.l-inner {
    padding-top: myrem(50);
    padding-bottom: myrem(100);
    max-width: myrem(1000);

    @include mq("sm"){
        max-width: 100%;
        padding-bottom: myrem(15);
    }

}

.p-doc-download.p-doc-download--complete .p-doc-download__inner.l-inner{
    max-width: myrem(1200);
    
    @include mq("md"){
        max-width: 100%;
        padding-bottom: myrem(15);
    }
}

.p-doc-download__ttl {
    color: $blue;
    font-size: myrem(42);
    font-weight: $bold;
    text-align: center;

    @include mq("md"){
      text-align: left;
      line-height: 1.5;
      
    }
    
}

.p-doc-download__text {
    
    margin-top: myrem(20);
    line-height: 1.5;
    font-size: myrem(16);
    text-align: center;

    @include mq("sm"){
        text-align: center;
        margin-top: myrem(30);
        font-size: myrem(24);
        line-height: 1.7;
    }
}


.p-doc-download__lists-ttl{
    color: $textblue;
    font-size: myrem(20);
    line-height: 1.5;
    font-weight: $bold;
    
    @include mq("sm"){
      font-size: myrem(26);
      text-align: left;
    }
}
.p-doc-download__lists-ttl::before,
.p-doc-download__lists-ttl::after{
    content: "～";
    font-size: inherit;
    
}


.p-doc-download__lists{
    text-align: left;
    margin-top: myrem(5);
    margin-left: auto;
    margin-right: auto;
    font-size: myrem(18);
    font-weight: $bold;
    line-height: 1.5;
    text-align: center;
    width: fit-content;

    @include mq("sm"){
        text-align: left;
        font-size: myrem(24);
        line-height: 1.7;
        margin-left: initial;
    }
}

.p-doc-download__lists li{
    text-align: left;
    width: fit-content;
}


.p-doc-download__lists li::before{
    content: "・";
    color: inherit;
}


.p-doc-download__form{

}

.p-doc-download__form{
    width: 100%;
}

.p-doc-download__main{
    margin-top: myrem(50);
    display: flex;
    gap: myrem(60);

    @include mq("sm"){
        flex-direction: column;
        gap: myrem(40);
    }
}

.p-doc-download__img{

}

.p-doc-download__img img{
    max-width: myrem(400);
    box-shadow: $base-shadow;

    @include mq("sm"){
      max-width: 100%;
    }
}

.p-doc-download__policy{
    margin-top: myrem(50);

    line-height: 1.5;
    font-size: myrem(14);
    text-align: left;

    @include mq("sm"){
        text-align: left;
        // margin-top: myrem(30);
        font-size: myrem(20);
        line-height: 1.7;
    }
}
.p-doc-download__policy-text{
    line-height: 1.7;
}

.p-doc-download__policy-text a{
    text-decoration: underline;
    transition: opacity .3s;
}

.p-doc-download__policy-text a:hover{
    opacity: .4;
}

.p-doc-download__policy-ttl{
    margin-top: myrem(10);
}

.p-doc-download__policy-lists{
}

.p-doc-download__policy-lists li{
    position: relative;
    padding-left: myrem(20);
    line-height: 1.7;
}
.p-doc-download__policy-lists li::before{
    position: absolute;
    content: "・";
    left: 0;
    top: myrem(1);
}




.p-download-form{
    // border-collapse: separate;
    // border-spacing: 0px 8px;
    margin-top: initial;

    font-size: myrem(13);
    // text-align: center;

    @include mq("sm"){
        text-align: left;
        margin-top: myrem(30);
        font-size: myrem(20);
        line-height: 1.7;
    }
}

.p-download-form__req{
    width: fit-content;
    padding: myrem(1.5) myrem(4) ;
    line-height: 1;
    font-size: myrem(10);
    background-color: rgb(246, 73, 73);
    color: $white;
    // white-space: nowrap;
}

.p-download-form__label{
    margin-left: myrem(6);
    vertical-align: middle;
}

.p-download-form__label > span{
    font-size: myrem(10);
    display: block;
    margin-top: myrem(-6);
    
}

.p-download-form__label > span.small{
    margin-top: myrem(1.5);
    font-size: myrem(10);
    
}

.p-download-form th{
    width: 50%;
    // width: myrem(150);
    // display: flex;
    // align-items: center;
    // align-items: flex-start;
    // flex-direction: column;
    // flex: 1;
    // gap: myrem(8);

    @include mq("sm"){
      width: 72%;
    }
}

.p-download-form tr{
    vertical-align: middle;
    display: block;
    border-top: 1px solid #D6D6D6;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    // width: 40%;
}

.p-download-form tr:last-child{
    border-bottom: 1px solid #D6D6D6;
    
}

.p-download-form tr:not(:first-child){
    // margin-top: myrem(20);
}
.p-download-form td,
.p-download-form th{
    vertical-align: middle;
}

.p-download-form td{
    // max-width: myrem(300);
    // width: 57%;
    width: 100%;
    background-color: transparent !important;
    // max-width: myrem(250);
}

.p-download-form td input{
    background-color: #FCFCFC;
    border: 1px solid #E1E1E1;
    border-radius: myrem(3);
    width: 100%;
    // height: 100%;
    font-size: myrem(13);
    box-sizing: border-box;
    padding: myrem(9);

    @include mq("sm"){
      font-size: myrem(20);
      
    }

}

.p-download-form td,
.p-download-form th{
    padding: myrem(14) 0;
}

.p-doc-complete{
    margin-top: myrem(50);
    display: flex;
    gap: myrem(60);

    @include mq("md"){
        gap: myrem(45);
        flex-direction: column;
    }
}

.p-doc-complete__right{
    width: 100%;
}

.p-doc-download__form button{
    margin-top: myrem(20);
    background-color: $deepblue;
    color: $white;
    font-weight: $bold;
    width: myrem(160);
    text-align: center;
    padding: myrem(9) myrem(3);
    font-size: myrem(16);
    border-radius: myrem(40);
    cursor: pointer;
    transition: opacity .2s;	

}

.p-doc-download__form button:hover{
    opacity: .3;
}
.p-doc-complete__left{
    width: 18%;
    width: myrem(300);    

    @include mq("md"){
        width: 100%;
        text-align: left;
    }
}
.p-doc-complete__text{
    @include mq("md"){
      font-size: myrem(22);
      
    }
}

.p-doc-complete__left-text{
    text-align: left;
    font-size: myrem(18);

    @include mq("md"){
        font-size: myrem(22);
        text-align: left;
    }
}

.p-doc-complete__left-text a.tel{
    pointer-events: none;

    @include mq("md"){
        pointer-events: initial;
        text-decoration: underline;
        color: $blue;
    }

}
.p-doc-complete__right{
    text-align: left;
}

.p-doc-complete__lists-ttl{
    margin-top: myrem(20);
    font-weight: $bold;
    font-size: myrem(24);
}

.p-doc-complete__lists{
    margin-top: myrem(3);
    font-size: myrem(22);
}
.p-doc-complete__lists li::before{
    content: "・";

}

.p-doc-complete__form{
    margin-top: myrem(40);
}

.p-doc-complete__form iframe{
    width: 100%;
}