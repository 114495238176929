@use "global" as *;
@use "sass:math";

.p-news {
}

.p-news__items {
    margin-top: myrem(29);
}

.p-news__ttl {
    text-align: left;
    font-size: myrem(24);
    font-weight: $bold;
    letter-spacing: 0.1em;
    color: $textblue;

    @include mq("sm") {
        font-size: myrem(36);
    }
}

.p-news__inner {
    // padding-top: myrem(50);
    // padding-bottom: myrem(50);
}

.p-news__inner.l-inner {
    max-width: myrem(1102);
}

.p-news__btn {
    margin-top: myrem(30);
}

.p-news__pagination {
    margin-top: myrem(30);

    @include mq("sm") {
        margin-top: myrem(20);
    }
}
