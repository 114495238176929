@use "global" as *;
@use "sass:math";

.l-faq-anchor {
    margin-top: myrem(100);

    @include mq("sm") {
        margin-top: myrem(50);
    }
}

.p-faq-anchor {
}

.p-faq-anchor__inner {
}

.l-inner {
}

.p-faq-anchor__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: myrem(30);
    column-gap: myrem(120);

    @include mq("sm") {
        gap: myrem(30);
        grid-template-columns: 1fr 1fr;
    }
}

.p-faq-anchor__link {
    display: block;
    position: relative;
    color: $deepblue;
    font-weight: $bold;
    line-height: math.div(26, 18);

    padding-bottom: myrem(35);
    font-size: myrem(18);

    @include mq("sm") {
        font-size: myrem(20);
    }
}

.p-faq-anchor__link::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: myrem(10);
    transform: translateX(-50%);
    // margin-top: myrem(3);
    aspect-ratio: 1/ 1;
    text-align: center;
    display: block;
    width: myrem(18);
    background-image: url("/img/2024/arrow-bottom.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @include mq("sm") {
        width: myrem(24);
        bottom: myrem(5);
    }
}

.p-faq-anchor__link::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    background-color: $deepblue;
    bottom: 0;
    opacity: 0;
    transition: opacity $base-anime-duration;

    @include mq("sm") {
        height: 2px;
    }
}
.p-faq-anchor__link:hover::after {
    opacity: 1;
}

.p-faq-anchor__text {
}
