@use "global" as *;
@use "sass:math";

.c-posts{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: myrem(28);
    max-width: myrem(1147);
    width: 100%;
    margin-left: auto;
    margin-right: auto;


    @include mq("md"){
        grid-template-columns: 1fr;
        max-width: myrem(800);
        row-gap: myrem(40);
    }
    @include mq("sm"){
    //   row-gap: myrem(20);
    }
}

.c-posts__item{
    /*グリットアイテムを3グリット分の大きさにする*/
    grid-row: span 2;
    /*サブグリットの指定*/
    display: grid;
    grid-template-rows: subgrid;
    gap: 0;
    width: 100%;
    text-align: center;
    position: relative;
    // border-right: 1px dashed $white;

    @include mq("md"){
        border: none;
    }
}
.c-posts__item:after{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    // background-color: $white;
    border-right: 1px dashed $white;

    @include mq("md"){
      border: initial;
    }
}

.c-posts__item:nth-child(3n+1)::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    // background-color: $white;
    border-left: 1px dashed $white;


    @include mq("md"){
        border: none;
    }
}

.c-posts__item {
    padding-top: myrem(46);
    padding-bottom: myrem(40);

    @include mq("md"){
        padding-top: myrem(30);
        padding-bottom: myrem(45);
    }
}
.c-posts__item > *{
    padding-left: myrem(43);
    padding-right: myrem(43);

    @include mq("sm"){
        padding-left: myrem(30);
        padding-right: myrem(30);
    }
}

.c-posts__item-img{
    position: relative;
}
.c-posts__item-img > img{
    border-radius: myrem(11);
    aspect-ratio: 297 / 200;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.c-posts__item-logo{
    position: absolute;
    background-color: $white;
    bottom: 0;
    left: myrem(43);
    width: myrem(148);
    height: myrem(40);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: myrem(10);

}

.c-posts__item-logo > img{
    padding: 0 myrem(5);

    display: block;
    object-fit: contain;
    width: auto;
    height: 100%;
}

.c-posts__item-cats ul{
    margin-top: myrem(26);
    display: flex;
    flex-wrap: wrap;
    gap: myrem(10);
}

.c-posts__item-cats ul li{
    display: block;
    font-size: myrem(14);
    font-weight: $medium;
    line-height: 1.1;
    color: $textblue;
    background-color: $white;
    border: 1px solid $textblue;
    padding: myrem(4.5) myrem(10);

    @include mq("md"){
        font-size: myrem(19);
    }
}

.c-posts__item-title{
    margin-top: myrem(10);
    text-align: left;
}

.c-posts__item-title{
    // display: inline; 
    text-align: left;
    font-size: myrem(16);
    font-weight: $regular;
    color: $textblue;
    // background: linear-gradient($textblue,$textblue) 0 100%/0 2px no-repeat;
    // transition: background .4s ,opacity .4s;
    transition: color .1s;
    line-height:  math.div(28, 16);
    letter-spacing: 0.05em;

    @include mq("md"){
        font-size: myrem($sp-base-fs);
    }
}
.c-posts__item-title a:hover{ 
    // opacity: 0.7;
    // background-size: 100% 2px;
}

// .c-posts__item a:hover .c-posts__item-title{
//     color: $blue;
// }

.c-posts__item{
}

.c-posts__item{
    border-radius: 1rem;
    transition: background-color .3s;
}
.c-posts__item:hover{
    background-color: #BCD3F2;
}
.c-posts__item{
    @include mq("md"){
        background-color: #BCD3F2;
    }
}

.c-posts.c-posts__tenpolab .c-posts__item:hover{
    background-color: #C1CDD5;
}

.c-posts.c-posts__tenpolab .c-posts__item{
    @include mq("md"){
        background-color: #C1CDD5;
    }
}