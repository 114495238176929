@use "global" as *;

.l-news {
    margin-top: myrem(80);

    @include mq("md") {
        margin-top: myrem(60);
    }
}

.l-news-archive {
    margin-top: myrem(60);

    @include mq("md") {
        margin-top: myrem(40);
    }
}
