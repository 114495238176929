@use "global" as *;
@use "sass:math";

.p-product__ttl {
    color: $textblue;
    font-size: myrem(32);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height: math.div(66, 32);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #cdd6dd;
    padding: 0 myrem(60);

    @include mq("md") {
        padding: initial;
        text-align: left;
    }
}

.p-product.p-product--bumon .p-product__ttl {
    position: relative;
    line-height: math.div(40, 32);
    width: 100%;
    padding-bottom: myrem(57);

    @include mq("sm") {
        padding-bottom: myrem(20);
    }
}

.p-product.p-product--bumon .p-product__ttl-img {
    position: absolute;
    left: 0;
    top: calc(50% - myrem(26));
    transform: translateY(-50%);
    width: myrem(136);
    height: myrem(136);

    @include mq("sm") {
        display: none;
        // left: initial;
        // right: 0;
        // width: myrem(120);
        // height: myrem(120);
        // top: calc(50% - myrem(20));
    }
}

.p-product__inner {
    padding-top: myrem(93);
    background-color: $white;
    padding-bottom: myrem(90);

    @include mq("sm") {
        padding-top: myrem(60);
        box-shadow: $base-shadow;
    }
}

.p-product__inner.l-inner {
    max-width: myrem(1328);
    padding-left: myrem(146);
    padding-right: myrem(146);

    @include mq("sm") {
        max-width: calc(100% - myrem($padding-pc) * 2);
        padding-left: myrem($padding-pc);
        padding-right: myrem($padding-pc);
    }
}

.p-product__items,
.p-product__main {
    margin-top: myrem(78);

    @include mq("sm") {
        margin-top: myrem(60);
    }
}

.p-product.p-product--bumon .p-product__items {
    margin-top: myrem(86);

    @include mq("sm") {
        margin-top: myrem(40);
    }
}

.p-product.p-product--bumon .p-product__item:not(:first-child) {
    margin-top: myrem(56);

    @include mq("sm") {
        margin-top: myrem(70);
    }
}

.c-product-ttl {
    color: $textblue;
    display: flex;
    gap: myrem(10);
    justify-content: space-between;
    align-items: center;

    @include mq("sm") {
        align-items: center;
        flex-direction: column;
    }
}

.c-product-ttl__info {
    display: flex;
    flex-direction: column;
    gap: myrem(20);
    max-width: myrem(480);
    text-align: left;

    @include mq("sm") {
        max-width: 100%;
    }
}

.c-product-ttl__ttl {
    font-size: myrem(32);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height: math.div(66, 32);
    border-bottom: 1px solid #cdd6dd;

    @include mq("sm") {
        font-size: myrem(34);
    }
}

.c-product-ttl.c-product-ttl--kinou .c-product-ttl__ttl {
    line-height: math.div(44, 32);
    border-bottom: initial;
}

.c-product-ttl__subttl {
    font-size: myrem(24);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height: math.div(36, 24);

    @include mq("sm") {
        font-size: myrem(28);
    }
}

.c-product-ttl__text {
    font-size: myrem(16);
    font-weight: $regular;
    line-height: math.div(28, 16);

    @include mq("sm") {
        font-size: myrem($sp-base-fs);
    }
}

.c-product-ttl__img {
    aspect-ratio: 245 / 245;
    width: myrem(245);

    @include mq("sm") {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        width: 100vw;
        display: none;
    }
}
