@use "global" as *;

.l-inner{
    padding-left:myrem($padding-pc);
    padding-right:myrem($padding-pc);
    max-width: myrem(1250);
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    @include mq("md"){
        padding-left: myrem($padding-pc);
        padding-right: myrem($padding-pc);
    }
}

