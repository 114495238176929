@use "global" as *;
@use "sass:math";

.p-dx{

}

.p-dx__inner.l-inner {
    max-width: myrem(1088);

}

.p-dx__item:not(:first-child) {
    margin-top: myrem(142);
}
