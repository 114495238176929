@use "global" as *;
@use "sass:math";

.p-price__items{
    margin-top: myrem(37);

}

.p-price__btn{
    margin-top: myrem(70);

    @include mq("sm"){
        margin-top: myrem(50);
    }
}