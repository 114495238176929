@use "global" as *;

.l-breadcrumb {
    margin-top: myrem(108);

    @include mq("md") {
        margin-top: myrem(40);
    }
}

.l-breadcrumb-small {
    margin-top: myrem(50);

    @include mq("md") {
        margin-top: myrem(20);
    }
}
