@use "global" as *;
@use "sass:math";

.p-contents {
}

.p-contents__inner.l-inner {
    max-width: myrem(1118);
}

.p-contents__items {
    display: flex;
    flex-direction: column;
    gap: myrem(56);
}

.c-content {
    color: $textblue;
    display: flex;
    gap: myrem(10);
    justify-content: space-between;
    align-items: center;

    @include mq("sm") {
        align-items: center;
        flex-direction: column;
    }
}

.c-content.c-content--top {
    align-items: flex-start;

    @include mq("sm") {
        align-items: flex-start;
    }
}

.c-content:nth-child(even) {
    flex-direction: row-reverse;

    @include mq("sm") {
        flex-direction: column;
    }
}

.c-content__info {
    display: flex;
    flex-direction: column;
    gap: myrem(20);
    max-width: myrem(480);
    text-align: left;

    @include mq("sm") {
        max-width: 100%;
    }
}

.c-content__ttl {
    font-size: myrem(32);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height: math.div(66, 32);
    border-bottom: 1px solid #cdd6dd;

    @include mq("sm") {
        border-bottom: 3px solid #cdd6dd;
        font-size: myrem(34);
    }
}
.c-content__subttl.c-content__subttl--large {
    font-size: myrem(28);
    line-height: math.div(40, 28);
}

.c-content__subttl {
    font-size: myrem(24);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height: math.div(36, 24);

    @include mq("sm") {
        font-size: myrem(28);
    }
}

.c-content__text {
    font-size: myrem(16);
    font-weight: $regular;
    line-height: math.div(28, 16);

    @include mq("sm") {
        font-size: myrem($sp-base-fs);
    }
}

.c-content__img {
    aspect-ratio: 480 / 270;
    width: myrem(480);

    @include mq("sm") {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        width: 100vw;
    }
}

.c-content__cats {
    margin-top: myrem(5);
}

.c-cats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: myrem(4);
    width: 100%;

    @include mq("sm") {
    }
}

.c-cats-grid.c-cats-grid--product {
    grid-template-columns: 1fr 1fr;
    gap: myrem(10);
}

.c-cats-grid__item {
    font-size: myrem(14);
    font-weight: $medium;
    letter-spacing: 0.05em;
    color: $textblue;
    background-color: #cee2ff;
    text-align: center;
    padding: myrem(4.1) myrem(10);
}

.c-cats-grid.c-cats-grid--product .c-cats-grid__item {
    padding: myrem(5.6) myrem(2);
}

.c-content.c-content--bumon .c-content__img {
    @include mq("sm") {
        width: 100%;
    }
}

.p-contents__flow-arrow {
    margin-top: myrem(5);
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: $lightblue;
    aspect-ratio: 88 / 28;
    width: myrem(88);
    clip-path: polygon(50% 100%, 0 0, 100% 0);

    @include mq("sm") {
        margin-top: myrem(15);
    }
}
