@use "global" as *;

.l-product {
    margin-top: myrem(70);

    @include mq("md") {
        margin-top: myrem(90);
    }
}

.l-product-repeat {
    margin-top: myrem(70);

    @include mq("md") {
        margin-top: myrem(60);
    }
}

.l-product-bumon {
    margin-top: myrem(140);

    @include mq("md") {
        margin-top: myrem(90);
    }
}

.l-product-kinou {
    margin-top: myrem(150);

    @include mq("md") {
        margin-top: myrem(60);
    }
}
