@use "global" as *;
@use "sass:math";

.p-bumon__items{
    display: grid;
    margin-top: myrem(49);
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: myrem(32);
    column-gap: myrem(32);
    max-width: myrem(1102);
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include mq("sm"){
        grid-template-columns: 1fr;
        max-width: 100%;
    }
}

.p-bumon__item{
    /*グリットアイテムを3グリット分の大きさにする*/
    grid-row: span 3;
    /*サブグリットの指定*/
    display: grid;
    grid-template-rows: subgrid;
    gap: 0;
    width: 100%;
}

.p-bumon__btn{
    margin-top: myrem(45);
}


.p-bumon-item{
    padding: myrem(23) 0 myrem(27);
    background-color: $white;
    text-align: center;
    border-radius: myrem(7);
    color: $textblue;
    border:  1px solid $textblue;

    @include mq("sm"){
        padding: myrem(35) 0 myrem(32);
      
    }
}

.p-bumon-item > *{
    padding-left: myrem(32);
    padding-right: myrem(32);

    @include mq("sm"){
        padding-left: myrem(45);
        padding-right: myrem(45);
    }
}

.p-bumon-item__img img{
    aspect-ratio: 152 / 159;
    width: myrem(152);

    @include mq("sm"){
        width: myrem(180);
    }
}

.p-bumon-item__ttl {
    margin-top: myrem(6);
    font-size: myrem(24);
    letter-spacing: 0.05em;
    font-weight: $bold;

    @include mq("md"){
    }
    
    @include mq("sm"){
        font-size: myrem(30);
        margin-top: myrem(12);
    }
}

.p-bumon-item__text {
    margin-top: myrem(3);
    font-size: myrem(16);
    font-weight: $regular;
    line-height:  math.div(28, 16);
    text-align: left;    

    @include mq("md"){
    }
    @include mq("sm"){
        font-size: myrem($sp-base-fs);
        margin-top: myrem(10);
    }
}

