@use "global" as *;
@use "sass:math";

.p-kinou{
    overflow: hidden;
}

.p-kinou__inner{
    padding-top: myrem(100);
    padding-bottom: myrem(100);
    max-width: myrem(1203);
    margin: 0 auto;
    width: 100%;
    text-align: center;

    @include mq("sm"){
        padding: 0 myrem($padding-pc);
        
    }
}


$image-x-pos: 146;

.p-kinou-item{
    position: relative;
    display: flex;
    justify-content: left;
    color: $textblue;

    @include mq("md"){
        flex-direction: column;
        align-items: center;
    }
}   

.p-kinou-item__inner{
    width: myrem(480);
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;

    @include mq("md"){
        text-align: center;
        align-items: center;
        width: 100%;
    }
}

.p-kinou-item__img{
    position: absolute;
    left: calc(50% - myrem($image-x-pos));
    top: myrem(-95);
    width: myrem(1106.65);

    @include mq("md"){
        margin-top: myrem(30);
        position: initial; 
    }
}


.p-kinou-item__ttl {
    font-size: myrem(18);
    font-weight: $semibold;
    letter-spacing: 0.05em;
    width: myrem(216);
    display: inline-block;
    padding: myrem(8.7) myrem(20);
    text-align: center;
    background-color: $white;
    border-radius: myrem(30);
    border: 1px solid $textblue;

    @include mq("sm"){
        font-size: myrem(26);
        width: myrem(240);
        padding: myrem(12) myrem(20);

    }
}

.p-kinou-item__subttl {
    margin-top: myrem(28);
    font-size: myrem(40);
    font-weight: $bold;
    line-height:  math.div(58, 40);
    letter-spacing: 0.05em;

    @include mq("sm"){
        text-align: left;
        width: 100%;
    }
}

.p-kinou-item__text {
    margin-top: myrem(22);
    font-size: myrem(16);
    font-weight: $regular;
    line-height:  math.div(28, 16);
    
    @include mq("sm"){
        font-size: myrem($sp-base-fs);
        text-align: left;
    }
}

.p-kinou__btn{
    margin-top: myrem(200);

    @include mq("sm"){
        margin-top: myrem(50);
    }
}

.p-kinou-item:not(:first-child){
    margin-top: myrem(280);

    @include mq("sm"){
        margin-top: myrem(100);
    }
}

.p-kinou-item:nth-child(even){
    justify-content: right;

}

.p-kinou-item:nth-child(even) .p-kinou-item__img{
    right: calc(50% - myrem($image-x-pos));
    left: initial;

}




