@use "global" as *;
@use "sass:math";


.p-flow-cus__ttl{
    font-size: myrem(32);
    font-weight: $bold;
    letter-spacing: 0.1em;
    color: $textblue;
    line-height:  math.div(46, 32);
}

.p-flow-cus__items {
    margin-top: myrem(39);
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: myrem(32);
    column-gap: myrem(48);
    max-width: myrem(1004);
    width: 100%;
    margin-left: auto;
    margin-right: auto;



    @include mq("sm"){
        grid-template-columns: 1fr;
        // max-width: myrem(500);
        max-width: 100%;
    }
}

.p-flow-cuss__item {
    /*グリットアイテムを3グリット分の大きさにする*/
    grid-row: span 4;
    /*サブグリットの指定*/
    display: grid;
    grid-template-rows: subgrid;
    gap: 0;
    width: 100%;
}

.c-flow-cus {
    padding: myrem(82) 0 myrem(42);
    background-color: $white;
    text-align: center;
    border-radius: myrem(18);
    color: $textblue;
    border: 1px solid #B5B5B5;
    text-align: center;
}

.c-flow-cus > *{
    padding-left: myrem(48);
    padding-right: myrem(48);

    @include mq("md"){
        padding-left: myrem(30);
        padding-right: myrem(30);          
    }

    @include mq("sm"){
        padding-left: myrem(48);
        padding-right: myrem(48);
    }
}

.c-flow-cus__top{
    display: flex;
    justify-content: space-between;
    gap: myrem(60);

    @include mq("md"){
        gap: myrem(20);
    }

    @include mq("sm"){
        gap: myrem(45);
    }
}

.c-flow-cus__ttl {
    margin-top: myrem(20);
    position: relative;
    text-align: left;
    height: min-content;
    width: 100%;
    font-size: myrem(20);
    font-weight: $bold;
    letter-spacing: 0.05em;

    @include mq("md"){
        font-size: myrem(27);

    }
}

.c-flow-cus__ttl::after{
    content: "";
    display: block;
    bottom: myrem(-10);
    left: 0;
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: $textblue;
}


.c-flow-cus__img{
    height: myrem(120);
    width: myrem(120);
}

.c-flow-cus__subttl{
    margin-top: myrem(-35);
    text-align: left;

    font-size: myrem(28);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height:  math.div(40, 28);

    @include mq("md"){
      font-size: myrem(32);
      
    }
}

.c-flow-cus__text{
    margin-top: myrem(22);
    text-align: left;
    font-size: myrem(16);
    font-weight: $regular;
    line-height:  math.div(28, 16);

    @include mq("md"){
      font-size: myrem($sp-base-fs);
    }
}

.c-flow-cus__btn{
    margin-top: myrem(33);
}