@use "global" as *;
@use "sass:math";

.p-mv{
    overflow: hidden;
}

.p-mv__inner{
    padding-top: myrem(30);
    padding-bottom: myrem(30);
    max-width: myrem(1334);
    margin-left: auto;
    text-align: center;

    @include mq("md"){
        padding: myrem(25) myrem(15) 0;

    }
}

.p-mv__main{
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    @include mq("sm"){
        flex-direction: column;
        // text-align: center;
    }
}


.p-mv__subttl {
    color: #0057FF;
    font-size: myrem(24);
    letter-spacing: 0.1em;
    line-height:  math.div(35, 24);
    font-weight: $fwblack;
    margin-left: myrem(13);

    @include mq("sm"){
        order: 1;
        margin-left: initial;
        font-size: 1.5rem;
        margin-left: myrem(20);
        line-height: 1.3;
    }
}

.p-mv__ttl {
    display: block;
    color: #0057FF;
    width: 100%;
    white-space: nowrap;
    font-size: myrem(58);
    line-height:  math.div(66, 50);
    letter-spacing: 0.05em;
    font-weight: $fwblack;
    margin-left: myrem(5);
    
    @include mq("sm"){
        order: 2;
        margin-top: myrem(10);
        font-size: 3.67rem;
        margin-left: myrem(10);
        line-height: 1.13;
        

    }
}

.p-mv__ttl span.p-mv__ttl--deepblue {
    color: $textblue;

}

.p-mv__ttl span.p-mv__ttl--small {
    font-size: myrem(40);

    @include mq("sm"){
      font-size: 2.7rem;
    }
}

.p-mv__lists {
    margin-left: myrem(14);
    margin-top: myrem(6);

    @include mq("sm"){
        order: 3;
        margin-top: 1rem;
    }
}

.p-mv__list {
    display: flex;
    align-items: center;
    position: relative;
    font-size: myrem(18);
    letter-spacing: 0.1em;
    line-height:  math.div(28, 18);
    font-weight: $bold;
    text-align: left;
    color: $textblue;
    padding-left: myrem(24);

    @include mq("sm"){
        padding: 0 0 0 myrem(25);
        font-size: 1.17rem;
        font-weight: $medium;
        letter-spacing: 0.05em;
    }
}

.p-mv__list:not(:first-child){
    @include mq("sm"){
        margin-top: myrem(-5);
    }
}

.p-mv__list::before{
    content:  "";     /* 空の要素作成 */
    position: absolute;
    width:  myrem(15);               /* 幅指定 */
    height:  myrem(15);              /* 高さ指定 */
    display:  inline-block;     /* インラインブロックにする */
    background-color: $deepblue;  /* 背景色指定 */
    top: myrem(15);                  /* 位置調整 */
    left: 0;
    transform: translateY(-50%);
    margin-right: 0.5rem;          /* 余白指定 */

    @include mq("sm"){
        width:  0.9rem;               /* 幅指定 */
        height:  0.9rem;              /* 高さ指定 */
        top: myrem(18);                  /* 位置調整 */
    }
}



.p-mv__btns{
    margin-top: myrem(19);
    display: flex;
    gap: myrem(30);

    @include mq("sm"){
        order: 4;
        margin-top: 1.6rem;
        gap: 1rem;
        align-items: center;
        padding: 0 0.5rem;
    }
}

.p-mv__results{
    margin-top: myrem(16);
    margin-left: myrem(5);
    display: flex;
    align-items: center;
    gap: myrem(10);

    @include mq("sm"){
        margin-top: -0.4rem;
        order: 6;
        margin-left: initial;      
        justify-content: center;
    }
}

.p-mv__result-img{
    width: myrem(271.9);
}


.p-mv__note{
    margin-top: myrem(4);
    margin-left: myrem(12);
    font-size: myrem(14);
    font-weight: $regular;
    line-height:  math.div(20, 14);
    color: $textblue;

    @include mq("sm"){
        margin-top: myrem(2);
        margin-left: myrem(30);
        // margin-top: initial;
        order: 7;
        font-size: 0.667rem;
    }
    
}

.p-mv__right{
    position: relative;
    width: 100%;
}

.p-mv__img{
    pointer-events: none;
    width: myrem(650.44);
    position: absolute;
    top: myrem(22);
    left: calc(50% + myrem(-35));
    
    @include mq("sm"){
        left: initial;
        top: initial;
        margin-top: .5rem;
        margin-top: initial;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        position: initial;
        order: 5;      
    }
}