@use "global" as *;
@use "sass:math";


.c-product-4f__inner{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: myrem(1035);

}

.c-product-4f__items {
    margin-top: myrem(37);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: myrem(60);
    column-gap: myrem(45);
    color: $textblue;

    @include mq("sm"){
        grid-template-columns: 1fr;
        // max-width: myrem(500);
        max-width: 100%;
    }
}

.c-product-4f__top-ttl{
    display: flex;
    font-size: myrem(24);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height:  math.div(35, 24);
    color: $textblue;
    text-align: left;
    position: relative;
    padding-left: myrem(19);

    @include mq("sm"){
      font-size: myrem(32);
      
    }
}

.c-product-4f__top-ttl:before{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: myrem(2);
    height: 80%;
    background-color: $textblue;
}

.c-product-4f__item {
    /*グリットアイテムを3グリット分の大きさにする*/
    grid-row: span 3;
    /*サブグリットの指定*/
    display: grid;
    grid-template-rows: subgrid;
    gap: 0;
    width: 100%;
    text-align: center;
    justify-items: center;
}

.c-product-4f__img {
    width: 89%;

    @include mq("sm"){
      width: 60%;
    }
}

.c-product-4f__ttl {
    margin-top: myrem(15);
    font-size: myrem(22);
    letter-spacing: 0.05em;
    line-height:  1.5;
    font-weight: $bold;

    @include mq("sm"){
        margin-top: myrem(20);
        font-size: myrem(28);
    }
}

.c-product-4f__text {
    text-align: left;
    font-size: myrem(16);
    font-weight: $regular;
    letter-spacing: 0.05em;
    line-height:  math.div(28, 16);
    margin-top: myrem(10);
    
    @include mq("sm"){
        margin-top: myrem(10);
        font-size: myrem(24);
    }
}

