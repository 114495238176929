@use "global" as *;
@use "sass:math";


.p-product-data{
    color: $textblue;
}

.p-product-data__top {
    display: flex;
    justify-content: center;
    gap: myrem(75);
    text-align: left;

    @include mq("sm"){
        flex-direction: column;
        gap: myrem(50);
    }
}

.p-product-data__top-item {
    width: 100%;
}

.p-product-data__top-img {

}

.p-product-data__top-ttl {
    font-size: myrem(22);
    font-weight: $bold;
    letter-spacing: 0.05em;
    line-height:  math.div(40, 22);

    @include mq("sm"){
        margin-top: myrem(10);
        font-size: myrem(28);
    }
}

.p-product-data__top-text {
    font-size: myrem(16);
    font-weight: $regular;
    line-height:  math.div(28, 16);

    @include mq("sm"){
        margin-top: myrem(10);
        font-size: myrem($sp-base-fs);      
    }
}


.p-product-data__bottom {
    margin-top: myrem(56);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: myrem(56);
}

.p-product-data__bottom-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: myrem(22);

    @include mq("sm"){
        flex-direction: column;
    }
}

.p-product-data__bottom-img {
    aspect-ratio: 690 / 270;
    width: 66.6023166%;

    @include mq("sm"){
        width: 100%;
    }
}

.p-product-data__bottom-cats {
    width: calc(100% - 66.6023166%);
    padding: myrem(12);

    @include mq("sm"){
        width: 100%;
    }

}

.p-product-data__bottom-ttl {
    font-size: myrem(22);
    font-weight: $bold;
    line-height:  math.div(32, 22);
    letter-spacing: 0.05em;
    text-align: left;
    align-self: flex-start;

    @include mq("sm"){
        font-size: myrem(28);
    }

}

.p-product-data__bottom-text {
    max-width: myrem(480);
    font-size: myrem(16);
    font-weight: $regular;
    line-height:  math.div(28, 16);
    text-align: left;
    
    @include mq("sm"){
        font-size: myrem($sp-base-fs);
      
    }
}

