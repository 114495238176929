@use "global" as *;
@use "sass:math";

.c-prices{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: myrem(32);
    column-gap: myrem(32);
    max-width: myrem(1102);
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include mq("sm"){
        grid-template-columns: 1fr;
        max-width: 100%;
    }
}

.c-prices__item{
    grid-row: span 2;
    display: grid;
    grid-template-rows: subgrid;
    gap: 0;
    width: 100%;

}

.c-price{
    padding: myrem(48) 0 myrem(40);
    background-color: #727880;
    text-align: center;
    border-radius: myrem(7);
    color: $white;
}

.c-price > *{
    padding-left: myrem(32);
    padding-right: myrem(32);
}

.c-price__ttl {

}

.c-price__ttl-en {
    font-size: myrem(20);
    font-weight: $medium;
    line-height: 1;
    color: $gray;
    font-family: $second-font-family;
    background-color: $white;
    padding: myrem(6) 0 myrem(4);
    
    @include mq("sm"){
        font-size: myrem(24);
        padding: myrem(12) 0 myrem(8);

    }
}



.c-price__ttl-ja {
    font-size: myrem(24);
    font-weight: $bold;
    line-height: 1;
    border: myrem(2) solid $white;
    padding: myrem(14) 0;

    @include mq("sm"){
        font-size: myrem(28);
        padding: myrem(20) 0;

        
    }
}


.c-price__text {
    margin-top: myrem(30);
    font-size: myrem(16);
    font-weight: $regular;
    line-height:  math.div(28, 16);
    text-align: left;    

    @include mq("md"){
        font-size: myrem($sp-base-fs);
    }
}

.c-price__price {
    margin-top: myrem(25);
    font-size: myrem(18);
    font-weight: $medium;

    @include mq("md"){
        font-size: myrem(22);
    }

    @include mq("sm"){
        font-size: myrem(30);
    }
}

.c-price__price span{
    font-size: myrem(28);
    font-family: $second-font-family;
    font-weight: $bold;
    
    @include mq("md"){
        font-size: myrem(40);
    }
}

// スタンダード
.c-price.c-price--blue{
    background-color: $blue;
}

.c-price.c-price--blue .c-price__ttl-en{
    color: $blue;
}

.c-price.c-price--brown{
    background-color: $textblue;
}

// プレミアム
.c-price.c-price--brown .c-price__ttl-en{
    color: $textblue;
    background-color: $brown;
}

.c-price.c-price--brown .c-price__ttl-ja{
    color: $brown;
    border: myrem(2) solid $brown;
}