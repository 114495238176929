@use "global" as *;
@use "sass:math";

// メイン
.c-news {
    display: flex;
    flex-direction: column;
    color: $textblue;
}

// 時間　カテゴリー
.c-news__info {
    display: flex;
    align-items: center;

    @include mq("sm") {
        justify-content: flex-start;
        width: 100%;
    }
}
.c-news__bottom {
    display: flex;
    align-items: center;
    gap: myrem(30);
    justify-content: space-between;
    width: 100%;

    @include mq("sm") {
        margin-top: myrem(10);
    }
}
// 画像
.c-news__imgs {
    display: flex;
    align-items: center;
    gap: myrem(30);
}

.c-news__item {
    border-top: 1px solid #cdd6dd;
}

.c-news__item:last-child {
    border-bottom: 1px solid #cdd6dd;
}

.c-news__link {
    width: 100%;
    height: 100%;
    display: flex;
    padding: myrem(15) 0;
    align-items: center;
    justify-content: space-between;
    transition: opacity 0.3s;

    @include mq("sm") {
        flex-direction: column;
    }
}

.c-news__link:hover {
    opacity: 0.2;
}

.c-news__time {
    font-size: myrem(16);
    font-weight: $regular;
    line-height: 1;
    letter-spacing: 0.02em;

    @include mq("sm") {
        font-size: myrem(18);
    }
}

.c-news__cats {
    margin-left: myrem(56);
    display: flex;
    flex-direction: column;
    gap: myrem(10);
    flex-wrap: wrap;
    width: myrem(120);

    @include mq("sm") {
        flex-direction: row;
        width: 100%;
    }
}

.c-news__cats li {
    width: 100%;
    line-height: 1.2;
    padding: myrem(6.67);
    background-color: #cee2ff;
    border-radius: myrem(10);
    font-size: myrem(14);
    font-weight: $regular;
    letter-spacing: 0.02em;

    @include mq("sm") {
        font-size: myrem(16);
        width: myrem(120);
    }
}

.c-news__ttl {
    margin-left: myrem(52);
    max-width: myrem(456);
    display: block;
    text-align: left;
    font-size: myrem(16);
    font-weight: $regular;
    letter-spacing: 0.05em;
    line-height: math.div(28, 16);
    width: 100%;

    @include mq("sm") {
        margin-left: initial;
        max-width: initial;
        font-size: myrem(20);
    }
}

.c-news__img {
    aspect-ratio: 182.66 / 93;
    width: myrem(182.66);
    object-fit: cover;

    @include mq("sm") {
        display: none;
    }
}

.c-news__news-icon {
    width: myrem(16);
}
