@use "global" as *;

.l-bumon{
    margin-top: myrem(90);

    @include mq("md"){
        margin-top: myrem(100);
    }
}


