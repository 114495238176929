@use "global" as *;
@use "sass:math";

.p-faq__inner.l-inner {
    text-align: center;
    max-width: myrem(1087);
}

.p-faq__ttl {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: myrem(75);

    @include mq("sm") {
        gap: initial;
    }
}

.p-faq__right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.p-faq__text {
    margin-top: myrem(47);
    font-size: myrem(32);
    letter-spacing: 0.1em;
    color: $textblue;

    @include mq("sm") {
        margin-top: myrem(30);
    }
}

.p-faq__img {
    margin-left: myrem(-24);
    max-width: myrem(298);
    width: 100%;

    @include mq("sm") {
        max-width: 70%;
    }
}

.p-faq__group {
    margin-top: myrem(62);

    @include mq("sm") {
        margin-top: myrem(40);
    }
}

.p-faq__btn {
    margin-top: myrem(62);

    @include mq("sm") {
        margin-top: myrem(50);
    }
}
