@use "global" as *;
@use "sass:math";

.l-flow {
    margin-top: myrem(11);
}

.p-flow {
}

.p-flow__inner.l-inner {
    border-radius: myrem(10);
    background-color: $white;
    padding-top: myrem(102);
    padding-bottom: myrem(121);
    max-width: myrem(1378);

    @include mq("sm") {
        padding-top: myrem(50);
        padding-bottom: myrem(45);
    }
}

.p-flow__body {
    padding: 0 myrem(130);

    @include mq("sm") {
        padding: 0 myrem(30);
    }
}

.p-flow__section:not(:first-child) {
    margin-top: myrem(62);

    @include mq("sm") {
        margin-top: myrem(100);
    }
}

.p-flow-section {
}

.p-flow-section__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $lightblue;
    padding-bottom: myrem(10);

    @include mq("sm") {
        flex-direction: column;
        padding-bottom: myrem(20);
        align-items: flex-start;
        gap: myrem(17);
    }
}

.p-flow-section__content {
    color: $textblue;
    display: flex;
    align-items: center;
    gap: myrem(18);

    @include mq("sm") {
    }
}

.p-flow-section__ttl-num {
    font-family: $second-font-family;
    font-size: myrem(60);
    font-weight: $medium;
    line-height: 1;

    @include mq("sm") {
        font-size: myrem(50);
    }
}

.p-flow-section__ttl {
    display: block;
    margin-top: myrem(13);
    font-size: myrem(28);
    font-weight: $bold;
    line-height: 1;

    @include mq("md") {
        font-size: myrem(36);
        margin-top: initial;
    }
}

.p-flow-section__cats {
    display: flex;
    align-items: center;
    gap: myrem(4);
    margin-top: myrem(23);

    @include mq("sm") {
        margin-top: initial;
        gap: myrem(8);
    }
}

.p-flow-section__cat {
    border: 1px solid $textblue;
    color: $textblue;
    display: block;
    font-size: myrem(14);
    line-height: 1;
    min-width: myrem(102);
    text-align: center;
    padding: myrem(4);
    font-weight: $bold;

    @include mq("sm") {
        font-size: myrem(18);
        padding: myrem(8);
        min-width: myrem(130);
    }
}

.p-flow-section__cat.current {
    color: $white;
    background-color: $textblue;
}

.p-flow-section__body {
    margin-top: myrem(38);
    display: grid;
    gap: myrem(78);
    grid-template-columns: 1fr 1fr 1fr;

    @include mq("sm") {
        grid-template-columns: 1fr;
        margin-top: myrem(20);
        gap: myrem(100);
    }
}

.p-flow-section__card {
    position: relative;
}

.p-flow-section__card:not(:first-child)::before {
    position: absolute;
    content: "";
    display: block;
    width: myrem(23);
    aspect-ratio: 23 / 52;
    background-color: $lightblue;
    top: 50%;
    left: myrem(-25);
    transform: translate(-100%, -50%);
    clip-path: polygon(0 0, 0% 100%, 100% 50%);

    @include mq("sm") {
        aspect-ratio: 52 / 23;
        width: myrem(65);
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        top: myrem(-30);
        left: 50%;
        transform: translate(-50%, -100%);
    }
}

.p-flow-card {
    padding: myrem(24) myrem(24) myrem(48);
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 0;
    background-color: $white;
    filter: drop-shadow(myrem(4) myrem(4) myrem(10) #d0dffc);

    @include mq("sm") {
        padding: myrem(35) myrem(45) myrem(55);
    }
}

.p-flow-card__img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: myrem(130);
    gap: myrem(20);

    @include mq("sm") {
        min-height: myrem(160);
    }
}

.p-flow-card__img.p-flow-card__img--logos img {
    width: myrem(90);
}

.p-flow-card__img img {
    aspect-ratio: 1 / 1;
    height: auto;
    width: myrem(130);

    @include mq("sm") {
        width: myrem(180);
    }
}

.p-flow-card__ttl {
    margin-top: myrem(10);
    color: $textblue;
    font-size: myrem(20);
    font-weight: $bold;
    line-height: math.div(29, 20);
    min-height: myrem(58);

    @include mq("sm") {
        margin-top: myrem(15);
        min-height: initial;
        font-size: myrem(24);
    }
}

.p-flow-card__text {
    margin-top: myrem(10);
    text-align: left;
    color: $textblue;
    font-size: myrem(16);
    font-weight: $regular;
    line-height: math.div(28, 16);

    @include mq("sm") {
        margin-top: myrem(15);
        font-size: myrem(20);
    }
}
