@use "global" as *;
@use "sass:math";

/* =====================================================
ブロックエディタ css
===================================================== */
.wp-block-style {
    margin-top: myrem(50);

    @include mq("sm") {
        margin-top: myrem(25);
    }
    * {
        margin-top: 15px;
        clear: both;
        font-family: "Noto Sans JP", sans-serif;
        // font-weight: 400;
        // line-height: math.div(28, 15);
        // font-size: 13px;

        @include mq("md") {
            // font-size: 15px;
        }
    }
    .wp-block-style__end {
        display: block;
        margin: 0;
    }
    /* リンク */
    a {
        color: #00e;
        text-decoration: underline;
    }

    a:visited {
        color: #551a8b;
        text-decoration: underline;
    }

    /* 太字 */
    strong {
        font-weight: bold;
    }

    /* リスト 位置調整 */
    li {
        list-style: initial;
    }

    ul,
    ol {
        padding-left: 20px;
    }
    ul li,
    ol li {
        margin-top: 0px;
        line-height: math.div(28, 15);
    }
    ul ol,
    ul ul,
    ol ol,
    ol ul {
        margin-top: myrem(0);
    }
    .wp-block-file .wp-block-file__button {
        padding: 4px 16px;
        color: #fff;
        text-decoration: initial;
    }
    selector {
        list-style-type: lower-alpha;
    }

    h2 {
        border-left: 2px solid $blue;
        padding-left: myrem(15);
        font-size: myrem(30);
    }

    /**************************************
 * グーテンベルクの画像幅調整
 * ***********************************/
    .wp-block-image img {
        height: auto !important;
    }
    .wp-block-image figure {
        float: initial !important;
    }
}
