@use "global" as *;
@use "sass:math";

.l-press-anchor {
    margin-top: myrem(100);

    @include mq("sm") {
        margin-top: myrem(50);
    }
}

.p-press-anchor {
}

.p-press-anchor__inner.l-inner {
    max-width: myrem(900);
}

.l-inner {
}

.p-press-anchor__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: myrem(30);

    @include mq("sm") {
        grid-template-columns: 1fr 1fr;
    }
}
.p-press-anchor__item {
}
.p-press-anchor__link {
    font-size: myrem(16);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: myrem(10) myrem(5);
    background-color: #cee2ff;
    // font-weight: $bold;
    // padding-bottom: myrem(40);
    border-radius: myrem(25);
    height: 100%;
    transition: opacity $base-anime-duration;
}

.p-press-anchor__link:hover {
    opacity: 0.6;
}

.p-press-anchor__item.current .p-press-anchor__link {
    pointer-events: none;
    background-color: $blue;
    color: $white;
}
